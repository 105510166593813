import React, { useState, useEffect } from "react";
import {
	Box,
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent,
	DialogContentText,
	LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NsnApi from "../../api/nsn/nsnAPI";
import { createPortal } from "react-dom";
// @ts-ignore: Implicit any module
import { isNullOrWhitespace } from "../../utils/validation-helpers.js";

interface TermHelpProps {
	open: boolean;
	term: string;
	onClose: any;
}

export default function TermHelpModal({ open, term, onClose }: TermHelpProps) {
	const [modalState, setModalState] = useState({
		title: "Term Help: " + term,
		isLoading: true,
		content: "",
	});

	useEffect(() => {
		async function fetchData() {
			if (isNullOrWhitespace(term)) return;

			setModalState((oldState) => ({
				...oldState,
				isLoading: true,
			}));

			const response = await NsnApi.getTermHelp(term);

			try {
				if (response.isSuccess) {
					setModalState((oldState) => ({
						...oldState,
						isLoading: false,
						title: "Term Help: " + term,
						content: response.result,
					}));
				} else {
					setModalState((oldState) => ({
						...oldState,
						isLoading: false,
					}));
				}
			} catch {
				setModalState((oldState) => ({
					...oldState,
					isLoading: false,
				}));
			}
		}

		fetchData();
	}, [term]);

	return createPortal(
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle
				sx={{
					m: 0,
					p: 2,
				}}
				id="dialog-title"
				style={{
					backgroundColor: "#306EB6",
					fontSize: "20px",
					fontWeight: "bold",
					color: "white",
					paddingRight: "60px",
				}}
			>
				{modalState.title}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 10,
					color: "white",
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				{modalState.isLoading ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						sx={{ width: "100%" }}
					>
						<LinearProgress sx={{ width: "100%" }} />
					</Box>
				) : (
					<DialogContentText
						id="dialog-description"
						dangerouslySetInnerHTML={{ __html: modalState.content }}
					></DialogContentText>
				)}
			</DialogContent>
		</Dialog>,
		document.getElementById("app-modal")!
	);
}
