// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";
import {
	ResponseDto,
	NsnQueryDto,
	NsnSearchResponseDto,
	NsnDetailsDto,
} from "../types/types";

const { NSN_SERVICE_URL } = config;

const Search = {
	/**
	 * Creates a new supplier.
	 * @param {NsnQueryDto} queryData The query parameters
	 */
	searchFull: async function (
		queryData: NsnQueryDto
	): Promise<ResponseDto<NsnSearchResponseDto>> {
		// Construct url
		var url = `${NSN_SERVICE_URL}/Search/Full`;
		var count = 0;
		let prop: keyof typeof queryData;
		for (prop in queryData) {
			if (count === 0) {
				url += "?";
			} else {
				url += "&";
			}

			url += `${prop}=${queryData[prop]}`;

			count++;
		}

		const { data } = await httpClient.get(url);
		return data as ResponseDto<NsnSearchResponseDto>;
	},

	/**
	 * Get the details for a particular NSN.
	 * @param nsn
	 * @returns
	 */
	getNsnDetails: async function (
		nsn: String
	): Promise<ResponseDto<NsnDetailsDto>> {
		const url = `${NSN_SERVICE_URL}/Search/Direct?nsn=${nsn}`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<NsnDetailsDto>;
	},
};

export default Search;
