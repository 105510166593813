import httpClient from "../configs/axiosConfig";
import config from "../../config";

const { SUPPLIERS_SERVICE_URL } = config;

const SupplierRelation = {
  addSupplierRelation: async function (
    companyName,
    internalId,
    supplierId,
    supplierRelationTypeId
  ) {
    const { data } = await httpClient.post(
      `${SUPPLIERS_SERVICE_URL}/SupplierRelation`,
      { companyName, internalId, supplierId, supplierRelationTypeId }
    );
    return data;
  },

  getAllRelatedSuppliersByRelationTypeId: async function (
    supplierId,
    relationTypeId
  ) {
    const { data } = await httpClient.get(
      `${SUPPLIERS_SERVICE_URL}/SupplierRelation/GetAllRelatedSuppliersByRelationTypeId?supplierId=${supplierId}&relationTypeId=${relationTypeId}`
    );
    return data.result;
  },

  deleteSupplierRelation: async function (id) {
    const { data } = await httpClient.delete(
      `${SUPPLIERS_SERVICE_URL}/SupplierRelation/${id}`
    );
    return data.result;
  },

  updateSupplierRelation: async function (
    id,
    companyName,
    internalId,
    supplierId,
    supplierRelationTypeId
  ) {
    const { data } = await httpClient.put(
      `${SUPPLIERS_SERVICE_URL}/SupplierRelation`,
      {
        id,
        companyName,
        ...(internalId != null && { internalId }),
        supplierId,
        supplierRelationTypeId,
      }
    );
    return data;
  },
};

export default SupplierRelation;
