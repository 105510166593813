import httpClient from "../configs/axiosConfig"
import config from "../../config"
/** @import { SupplierCreateDto } from '../types/types' */

const { DNB_SERVICE_URL } = config;

const DnbAPI = {
    getCompanyNameByDuns: async function (duns) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBService/GetDnBCompanyNameByDuns/${duns}`);
      return data.result;
    },

    getCompanyInformationByDuns: async function (duns) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBService/GetCompanyInformation/${duns}`);
      return data.result;
    },

    refreshCompanyInformationByDuns: async function (duns) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBService/RefreshCompanyInformation/${duns}`);
      return data.result;
    },

    getAssessmentDataById: async function (id, refresh = false) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetAssessmentData/${id}?refresh=${refresh}`);
      return data.result;
    },

    getSuitsDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetSuitsData/${id}`);
      return data;
    },

    getBankruptcyDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetBankruptcyData/${id}`);
      return data;
    },

    getLiensDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetLiensData/${id}`);
      return data;
    },

    getClaimsDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetClaimsData/${id}`);
      return data;
    },

    getLiquidationsDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetLiquidationsData/${id}`);
      return data;
    },

    getInsolvenciesDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetInsolvenciesData/${id}`);
      return data;
    },

    getJudgmentsDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetJudgmentsData/${id}`);
      return data;
    },

    getExclusionsDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetExclusionsData/${id}`);
      return data;
    },

    getDnbOtherItemsDataById: async function (id) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetDnbOtherItemsData/${id}`);
      return data;
    },

    getCompanyFinancialsById: async function (id, refresh = false) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetCompanyFinancials/${id}?refresh=${refresh}`);
      return data.result;
    },

    getPrincipalsDataById: async function (id, refresh = false) {
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetPrincipalsData/${id}?forceUpdate=${refresh}`);
      return data.result;
    },

    getFamilyTreeDataById: async function (id, refresh = false){
      const { data } = await httpClient.get(`${DNB_SERVICE_URL}/DnBData/GetFamilyTreeData/${id}?forceUpdate=${refresh}`);
      return data.result;
    },

}

export default DnbAPI;