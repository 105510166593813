import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { number } from "yargs";

interface KeywordModel {
  id: string | number;
  modelName: string;
};

interface KeywordModelsMenu {
  id: string | number;
  description: string;
}



interface KeywordModelState {
  allKeywordModel: KeywordModel[];
  selectedKeywordModel: KeywordModel;
  keywordsModelsMenu: KeywordModelsMenu[];
}

const initialState: KeywordModelState = {
  allKeywordModel: [],
  selectedKeywordModel: {
    id: "",
    modelName: '',
  },
  keywordsModelsMenu: [],
};


const keywordModelSlice = createSlice({
  name: 'keywordModel',
  initialState,
  reducers: {
    addKeywordModel: (state, action: PayloadAction<{ allKeywordModel: KeywordModel[] }>) => {
      return { ...state, allKeywordModel: [...action.payload.allKeywordModel] };
    },
    addSelectedKeywordModel: (state, action: PayloadAction<{ selectedKeywordModel: KeywordModel }>) => {
      return { ...state, selectedKeywordModel: action.payload.selectedKeywordModel };
    },
    addKeywordModelsMenu: (state, action: PayloadAction<{keywordsModelsMenu: KeywordModelsMenu[]}>) => {
      return {...state, keywordsModelsMenu: [...action.payload.keywordsModelsMenu]}
    }
  },
});

export const { addKeywordModel, addSelectedKeywordModel, addKeywordModelsMenu } = keywordModelSlice.actions;
export default keywordModelSlice.reducer;