// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";

const { KEYWORD_MODELS_SERVICE_URL, ALTRUITY_API_URL } = config;

interface Bodykeywordmodel {
  modelName: string;
  keywords: string;
  ownerId: number;
  searchFrequencyId: number;
  sentimentRuleId: number;
  isActive: boolean;
}
interface KeywordModel {
  id: number;
  modelName: string;
}

const KeywordModel = {
  getKeywordModelById: async function (id: number) {
    const { data } = await httpClient.get<{result: KeywordModel[]}>(
      `${KEYWORD_MODELS_SERVICE_URL}/KeywordModel/${id}`
    );
    return data.result;
  },

  getKeywordById: async function (id: number) {
    const { data } = await httpClient.get<{result: KeywordModel[]}>(
      `${ALTRUITY_API_URL}/GetKeywordModelById/${id}`
    );
    return data.result;
  },

  getAllKeywordModel: async function () {
    const { data } = await httpClient.get(`${KEYWORD_MODELS_SERVICE_URL}/KeywordModel/GetAll`);
  const response = data.result.sort((a:KeywordModel, b:KeywordModel) =>
    a.modelName.localeCompare(b.modelName)
  );
    return response
  },
  getAllSentimentRule: async function () {
    const { data } = await httpClient.get(`${KEYWORD_MODELS_SERVICE_URL}/Sentimentrule/GetAll`);
    return data.result;
  },
  getAllSearchFrequency: async function () {
    const { data } = await httpClient.get(`${KEYWORD_MODELS_SERVICE_URL}/SearchFrequency/GetAll`);
    return data.result;
  },
  createKeywordModel: async function (body:Bodykeywordmodel) {
    const { data } = await httpClient.post(`${KEYWORD_MODELS_SERVICE_URL}/KeywordModel`,body);
    return data.result;
  },

  getRiskModelsMenuItems: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/KeywordModels/GetRiskModelsMenuItems`);
    return data.result;
  }
  
};

export default KeywordModel;
