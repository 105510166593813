import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RiskCases {
  id: number;
  riskCaseName: string;
  description: string;
  ownerId: number;
  subscribers: string;
  keywords: string;
  createdById: number;
  measures: [];
  articles: [];
  suppliers: [];
  logEntries: [];
}

const initialState = {
  allRiskCases: [] as RiskCases[],
  menuRiskCase:{
    byKeywordModel: {},
    bySupplier: {},
    archived: {
      byKeywordModel: {},
      bySupplier: {},
    },
  },
  currentRiskCaseName: '',
};

const riskCasesSlice  = createSlice({
  name: 'riskCases',
  initialState,
  reducers: {
    addRiskCases: (state, action: PayloadAction<RiskCases[]>) => {
      const newRiskCases = action.payload.filter(
        (newRiskCase) =>
          !state.allRiskCases.some((existingRiskCase) => existingRiskCase.id === newRiskCase.id)
      );
      state.allRiskCases = [...state.allRiskCases, ...newRiskCases];
    },
    setMenuRiskCase: (state, action) => {
      state.menuRiskCase = action.payload;
    },
    setRiskCurrentCaseName: (state, action: PayloadAction<string>) => {
      state.currentRiskCaseName = action.payload;
    },
    updateRiskCaseName: (state, action: PayloadAction<{ id: number, newName: string }>) => {
      const { id, newName } = action.payload;

      const updateRiskCaseRecursive = (obj: any, path: string) => {
        if (obj && typeof obj === 'object') {
          console.log(`Recorriendo el objeto en: ${path}`);

          for (const key in obj) {
            if (obj[key].items && Array.isArray(obj[key].items)) {

              for (let i = 0; i < obj[key].items.length; i++) {
                const item = obj[key].items[i];

                if (item.id === id) {
                  item.description = newName;
                }
              }

              obj[key].items.sort((a: any, b: any) => a.description.localeCompare(b.description));
            } else {
              updateRiskCaseRecursive(obj[key], `${path} -> ${key}`);
            }
          }
        }
      };

      updateRiskCaseRecursive(state.menuRiskCase.byKeywordModel, 'menuRiskCase.byKeywordModel');
      updateRiskCaseRecursive(state.menuRiskCase.bySupplier, 'menuRiskCase.bySupplier');

      state.currentRiskCaseName = newName;
    },

  },
});

export const { addRiskCases, setMenuRiskCase, setRiskCurrentCaseName, updateRiskCaseName } = riskCasesSlice.actions;
export default riskCasesSlice.reducer;
