import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import PopupArticle from '../../../../components/risk-discovery/risk-discovery-popup/PopupArticle';

interface Article {
  id: number;
  title: string;
  pubDate?: string;
  sourceDomain: string;
  url: string;
  sentimentMetrics: string;
  semanticSummary: string;
  description: string;
  imageUrl: string;
  suppliers: string;
  positiveSentiment: number;
  negativeSentiment: number;
}

const Articles = ({ articles }: { articles: Article[] }) => {
  const [localArticles, setLocalArticles] = useState(articles);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleViewClick = (index: number) => {
    setCurrentIndex(index);
    setPopupVisible(true);
  };



  const closePopup = () => {
    setPopupVisible(false);
  };


  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Summary Description</TableCell>
            <TableCell align="center">Action</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          {articles.map((article, index) => (
            <TableRow key={article.id}>
              <TableCell>{article.title}</TableCell>
              <TableCell>{article.semanticSummary}</TableCell>
              <TableCell align="center">
                <Button
                  onClick={() => handleViewClick(index)}
                  style={{
                    background: 'none',
                    color: '#007BFF',
                    padding: 0,
                    textTransform: 'none',
                    minWidth: 'auto',
                    fontSize: '14px',
                  }}
                >
                  VIEW
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isPopupVisible && (
        <PopupArticle
          onClose={closePopup}
          articles={localArticles as any}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      )}
    </>
  );
};

export default Articles;
