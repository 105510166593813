// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';

const { ALTRUITY_API_URL, ARTICLES_SERVICE_URL } = config;

const Articles = {
  getArticleById: async function (id: number) {
    const { data } = await httpClient.get(
      `${ARTICLES_SERVICE_URL}/Article/${id}`
    );
    return data.result;
  },
  getArticlesByKeywordModel: async function (id: number, archived: boolean) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskDiscovery/GetArticlesByKeywordModel/${id}?archived=${archived}`
    );
    return data.result;
  },
  getArticlesBysupplier: async function (id: number, archived: boolean) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskDiscovery/GetArticlesBySupplier/${id}?archived=${archived}`
    );
    return data.result;
  },
  getAdditionalKeywordsByArticleId: async function (id: number) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskDiscovery/GetAdditionalKeywords/${id}`
    );
    return data.result;
  },
  addKeywordToModel: async function (keywordModelId: number, keyword: string) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/AddKeywordToModel`,
      { keywordModelId, keyword }
    );
    return data.result;
  },
};

export default Articles;
