import React from "react";
import {
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  fieldToFocus: string;
  isCreatable: boolean;
}

const generateUniqueId = () => {
  return `${Date.now()}-${Math.random().toString(36).slice(2, 11)}`;
};

const EditToolbar = (props: EditToolbarProps) => {
  const { setRows, setRowModesModel, fieldToFocus, isCreatable } = props;

  const handleClick = () => {
    const id = generateUniqueId();
    setRows((oldRows = []) => [...oldRows, { id, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus },
    }));
  };

  return (
    isCreatable && (
      <GridToolbarContainer style={{ display: "flex", justifyContent: "end" }}>
        <Tooltip title="Add a row">
          <IconButton onClick={handleClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </GridToolbarContainer>
    )
  );
};

export default EditToolbar;
