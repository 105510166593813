import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
  Collapse,
  MenuItem,
  Select,
  LinearProgress,
  Box,
  Chip,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { es } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AttachFile as AttachFileIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import SelectBoxOption from '../../../../types/SelectBoxOption';
import GetAppIcon from '@mui/icons-material/GetApp';
import formatDate from '../../../../utils/format-date';

interface FileData {
  riskCaseLogEntryId?: number;
  filename: string;
  operationType: string;
  base64Data: string | null;
}

interface CaseLogEntry {
  riskCaseLogEntryId?: number;
  date?: string | null;
  authorFullName?: string;
  entryText: string;
  files: FileData[];
}

// interface CaseLogProps {
//   owners?: SelectBoxOption[];
//   formData?: any;
//   setFormData?: any;
// }
interface CaseLogProps {
  owners?: SelectBoxOption[];
  formData?: any;
  updateCaseLogs?: (newCaseLogs: CaseLogEntry[]) => void;
  setFormData?: React.Dispatch<React.SetStateAction<any>>;
}

const CaseLog: React.FC<CaseLogProps> = ({
  owners,
  formData,
  updateCaseLogs,
  setFormData,
}) => {
  const [entries, setEntries] = useState<CaseLogEntry[]>(
    formData?.caseLogs?.caseLogs || []
  );
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [newEntry, setNewEntry] = useState<CaseLogEntry>({
    date: new Date().toLocaleDateString(),
    authorFullName: '',
    entryText: '',
    files: [],
  });

  const handleEditClick = (index: number) => {
    setEditingIndex(index);
    setNewEntry({
      ...entries[index],
      files: entries[index].files || [],
    });
  };

  const handleAddNewEntryClick = () => {
    setEditingIndex(-1);
    setNewEntry({
      date: new Date().toLocaleDateString(),
      authorFullName: formData?.owner?.name || '',
      entryText: '',
      files: [],
    });
  };

  // const handleSaveClick = () => {
  //   let updatedCaseLogs: CaseLogEntry[];
  //   if (editingIndex === -1) {
  //     const newEntryWithId: CaseLogEntry = {
  //       ...newEntry,
  //       riskCaseLogEntryId: 0,
  //       files: newEntry.files.map(file => ({
  //         ...file,
  //         riskCaseLogEntryId: 0
  //       }))
  //     };
  //     updatedCaseLogs = [...entries, newEntryWithId];
  //   } else {
  //     updatedCaseLogs = entries.map((entry, index) =>
  //       index === editingIndex ? {
  //         ...newEntry,
  //         riskCaseLogEntryId: 0,
  //         files: newEntry.files.map(file => ({
  //           ...file,
  //           riskCaseLogEntryId: 0,
  //         }))
  //       } : entry
  //     );
  //   }
  //   setFormData({ ...formData, caseLogs: { caseLogs: updatedCaseLogs } });
  //   setEntries(updatedCaseLogs);
  //   setEditingIndex(null);
  // };
  // const handleSaveClick = () => {
  //   let updatedCaseLogs: CaseLogEntry[];
  //   if (editingIndex === -1) {
  //     const newEntryWithId: CaseLogEntry = {
  //       ...newEntry,
  //       riskCaseLogEntryId: 0,
  //       files: newEntry.files.map(file => ({
  //         ...file,
  //         riskCaseLogEntryId: 0
  //       }))
  //     };
  //     updatedCaseLogs = [...entries, newEntryWithId];
  //   } else {
  //     updatedCaseLogs = entries.map((entry, index) =>
  //       index === editingIndex ? {
  //         ...newEntry,
  //         riskCaseLogEntryId: 0,
  //         files: newEntry.files.map(file => ({
  //           ...file,
  //           riskCaseLogEntryId: 0,
  //         }))
  //       } : entry
  //     );
  //   }
  //   updateCaseLogs?.(updatedCaseLogs);
  //   setEntries(updatedCaseLogs);
  //   setEditingIndex(null);
  // };

  const handleSaveClick = () => {
    let updatedCaseLogs: CaseLogEntry[];
    if (editingIndex === -1) {
      const { date, ...newEntryWithoutDate } = newEntry;
      const newEntryWithId: CaseLogEntry = {
        ...newEntryWithoutDate,
        date: newEntry.date || new Date().toISOString(),
        riskCaseLogEntryId: 0,
        files: newEntry.files.map((file) => ({
          ...file,
          riskCaseLogEntryId: 0,
        })),
      };
      updatedCaseLogs = [...entries, newEntryWithId];
    } else {
      updatedCaseLogs = entries.map((entry, index) =>
        index === editingIndex
          ? {
              ...newEntry,
              date: newEntry.date || new Date().toISOString(),
              riskCaseLogEntryId: 0,
              files: newEntry.files.map((file) => ({
                ...file,
                riskCaseLogEntryId: 0,
              })),
            }
          : entry
      );
    }

    // updatedCaseLogs = updatedCaseLogs.map(({ date, ...entryWithoutDate }) => entryWithoutDate);

    updateCaseLogs?.(updatedCaseLogs);
    setFormData?.((prevFormData: any) => ({
      ...prevFormData,
      caseLogs: { caseLogs: updatedCaseLogs },
    }));
    setEntries(updatedCaseLogs);
    setEditingIndex(null);
  };

  const handleCancelClick = () => {
    setEditingIndex(null);
  };

  const handleDeleteClick = (index: number) => {
    const updatedCaseLogs = entries.filter((_, i) => i !== index);
    updateCaseLogs?.(updatedCaseLogs);
    setEntries(updatedCaseLogs);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadProgress(10);
      setIsUploading(true);
      const totalFiles = e.target.files.length;
      let filesProcessed = 0;

      setTimeout(() => setUploadProgress(1), 50);

      const processFile = (index: number) => {
        if (index >= totalFiles) {
          setIsUploading(false);
          setUploadProgress(70);
          return;
        }

        const file = e.target.files![index];
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64Data = event.target?.result as string;
          filesProcessed++;
          const progress = (filesProcessed / totalFiles) * 100;
          setUploadProgress(progress);

          setNewEntry((prev) => ({
            ...prev,
            files: [
              ...prev.files,
              {
                filename: file.name,
                operationType: 'ADD',
                base64Data: base64Data.split(',')[1],
              },
            ],
          }));

          setTimeout(() => processFile(index + 1), 100);
        };

        reader.readAsDataURL(file);
      };

      processFile(0);
    }
  };

  const handleRemoveFile = (fileToRemove: FileData) => {
    setNewEntry((prev) => ({
      ...prev,
      files: prev.files.filter(
        (file) => file.filename !== fileToRemove.filename
      ),
    }));
  };

  const handleDownload = useCallback((file: FileData) => {
    const byteCharacters = atob(file.base64Data || '');
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = file.filename;
    link.click();
  }, []);

  return (
    <TableContainer
      component={Paper}
      style={{ padding: '20px', marginTop: '20px' }}
    >
      {editingIndex !== null && (
        <Collapse in={true}>
          <TableRow>
            <TableCell colSpan={5}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
              >
                <DatePicker
                  label="Date"
                  value={newEntry.date ? new Date(newEntry.date) : null}
                  onChange={(newDate) => {
                    setNewEntry({
                      ...newEntry,
                      date: newDate ? newDate.toISOString() : null,
                    });
                  }}
                  slotProps={{ textField: { style: { margin: '5px' } } }}
                />
              </LocalizationProvider>
              <Select
                margin="dense"
                label="Author"
                fullWidth
                value={newEntry.authorFullName || ''}
                onChange={(e) =>
                  setNewEntry({
                    ...newEntry,
                    authorFullName: e.target.value as string,
                  })
                }
                disabled={editingIndex !== -1}
              >
                {owners && owners.length > 0 ? (
                  owners.map((owner) => (
                    <MenuItem key={owner.key} value={owner.name}>
                      {owner.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No authors available</MenuItem>
                )}
              </Select>
              <TextField
                margin="dense"
                label="Entry"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={newEntry.entryText}
                onChange={(e) =>
                  setNewEntry({ ...newEntry, entryText: e.target.value })
                }
              />
              <Box
                sx={{
                  margin: '10px 0',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Button variant="contained" component="label">
                  Upload Files
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={handleFileUpload}
                  />
                </Button>
                {isUploading && (
                  <Box sx={{ mt: 2, width: '100%', maxWidth: '300px' }}>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress}
                      sx={{
                        height: 8,
                        borderRadius: 4,
                        '& .MuiLinearProgress-bar': {
                          borderRadius: 4,
                        },
                      }}
                    />
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}
                    >
                      {`${Math.round(uploadProgress)}%`}
                    </Box>
                  </Box>
                )}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                  {newEntry.files.map((file, index) => (
                    <Chip
                      key={index}
                      icon={<AttachFileIcon sx={{ fontSize: '0.8rem' }} />}
                      label={file.filename}
                      onDelete={() => handleRemoveFile(file)}
                      sx={{
                        bgcolor: 'grey.200',
                        color: 'text.primary',
                        '& .MuiChip-deleteIcon': {
                          color: 'text.secondary',
                        },
                        height: '24px',
                        fontSize: '0.75rem',
                      }}
                    />
                  ))}
                </Box>
              </Box>
              <Button onClick={handleCancelClick} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleSaveClick}
                color="primary"
                style={{ marginLeft: '10px' }}
              >
                {editingIndex === -1 ? 'Add Entry' : 'Save Changes'}
              </Button>
            </TableCell>
          </TableRow>
        </Collapse>
      )}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddNewEntryClick}
        style={{ margin: '10px' }}
      >
        Add New Log Entry
      </Button>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Entry</TableCell>
            <TableCell align="center">Files</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  {entry.date ? formatDate(entry.date) : ''}
                </TableCell>
                <TableCell>{entry.authorFullName}</TableCell>
                <TableCell>{entry.entryText}</TableCell>
                <TableCell align="center">
                  {entry.files && entry.files.length > 0 ? (
                    <Tooltip
                      title={
                        <List dense>
                          {entry.files.map((file, fileIndex) => (
                            <ListItem
                              key={fileIndex}
                              button
                              onClick={() => handleDownload(file)}
                            >
                              <ListItemIcon>
                                <GetAppIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary={file.filename} />
                            </ListItem>
                          ))}
                        </List>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: { pointerEvents: 'auto' } as const,
                        },
                      }}
                    >
                      <IconButton size="small">
                        <AttachFileIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => handleEditClick(index)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeleteClick(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CaseLog;
