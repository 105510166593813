import React, { useState } from "react";
import RiskDiscoveryHits from "../../components/risk-discovery/risk-discovery-hits/RiskDiscoveryHits";
import RiskDiscoveryHitCollections from "../../components/risk-discovery/risk-discovery-hit-collections/RiskDiscoveryHitCollections";
import "./index.scss";
import RiskCasePopup from "../../components/risk-discovery/risk-case-popup/RiskCasePopup";
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../slices/discoverySlice";

const Discovery = () => {
  const [key, setKey] = useState(0);

  const snackbar = useSelector((state: any) => state.discovery.snackbar);

  const dispatch = useDispatch();

  const closeSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setSnackbar({ open: false }));
  };

  return (
    <div className="risk-discovery-container">
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={snackbar?.severity === "error" ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      <RiskDiscoveryHits keyValue={key} setKey={setKey} />
      <RiskDiscoveryHitCollections key={key} />
      <RiskCasePopup />
    </div>
  );
};

export default Discovery;
