import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import configAzureLogin from "../configAzureLogin";

const msalInstance = new PublicClientApplication({
	auth: {
		clientId: configAzureLogin.appId,
		redirectUri: configAzureLogin.redirectUri,
		authority: configAzureLogin.authority,
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: true,
	},
});

export const handleAuth = createAsyncThunk(
	"auth/handleAuth",
	async (_, { rejectWithValue }) => {
		try {
			await msalInstance.initialize();
			const response = await msalInstance.handleRedirectPromise();

			if (response && response.account) {
				var acct = response.account;
				acct.tenantProfiles = null; // Throws not serializable error.
				const tokenResponse = await msalInstance.acquireTokenSilent({
					account: acct,
					scopes: configAzureLogin.scopes,
				});
				return {
					account: acct,
					accessToken: tokenResponse.accessToken,
				};
			} else {
				const accounts = msalInstance.getAllAccounts();
				if (accounts.length > 0) {
					var userAcct = accounts[0];
					userAcct.tenantProfiles = null; // Throws not serializable error.

					const tokenResponse = await msalInstance.acquireTokenSilent(
						{
							account: userAcct,
							scopes: configAzureLogin.scopes,
						}
					);

					return {
						account: userAcct,
						accessToken: tokenResponse.accessToken,
					};
				} else {
					await msalInstance.loginRedirect({
						scopes: configAzureLogin.scopes,
					});
				}
			}
		} catch (error) {
			if (error instanceof InteractionType) {
				await msalInstance.loginRedirect({
					scopes: configAzureLogin.scopes,
				});
			} else {
				console.error(error);
				return rejectWithValue(error.message);
			}
		}
	}
);

const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: null,
		accessToken: null,
		loading: false,
		error: null,
	},
	reducers: {
		logout: (state) => {
			msalInstance.logoutRedirect();
			state.user = null;
			state.accessToken = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleAuth.pending, (state) => {
				state.loading = true;
			})
			.addCase(handleAuth.fulfilled, (state, action) => {
				state.user = action.payload.account.username;
				state.accessToken = action.payload.accessToken;
				state.loading = false;
			})
			.addCase(handleAuth.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			});
	},
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
