import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import { useDispatch, useSelector } from "react-redux";
import { handleAuth } from "./slices/authSlice";
import config from "devextreme/core/config";
import { licenseKey } from "./devextreme-license";

function App() {
	const dispatch = useDispatch();
	const { user, loading } = useSelector((state) => state.auth);

	config({ licenseKey });

	useEffect(() => {
		dispatch(handleAuth());
	}, [dispatch]);

	if (loading) {
		return <LoadPanel visible={true} />;
	}

	if (!user) {
		return <div>Redirecting to login...</div>;
	}

	return <Content />;
}

export default function Root() {
	const screenSizeClass = useScreenSizeClass();

	return (
		<Router>
			<NavigationProvider>
				<div className={`app ${screenSizeClass}`}>
					<App />
				</div>
			</NavigationProvider>
		</Router>
	);
}
