import React, { useEffect, useState } from 'react';
import SelectBoxOption from '../../../types/SelectBoxOption';
import { Button, Box } from '@mui/material';
import RiskCaseApi from '../../../api/riskCase/riskCaseApi';
import { useParams } from 'react-router';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import MeasuresTableRiskCase from './measureTableRiskCase';
import GeneralInformationRiskCase from './generalInformation';
import { ConstructionOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { setRiskCurrentCaseName, updateRiskCaseName } from '../../../slices/riskCasesSlice';
import CustomSnackbar from '../../custom-snackbar';
import { AlertColor } from '@mui/material';

interface Measure {
  riskMeasureId: number;
  description: string;
  availableRiskMeasureLevels: RiskMeasureLevel[];
  riskMeasureName: string;
  isRequired?: boolean;
}
interface RiskMeasureLevel {
  id: number;
  label: string;
  comments: string;
  selectedLevelId: number;
  isRequired: boolean;
}
interface Owner {
  key: string;
  name: string;
}

interface RiskCaseOverviewProps {
  generalInformation: {
    riskCaseId: number,
    dateAdded: string,
    dateUpdated: string,
    dateDeleted: null,
    isActive: boolean,
    isDeleted: boolean,
    riskCaseName: string,
    comments: string,
    description: string,
    owner: Owner | null,
    subscribers: any[],
    suppliers: any[];
    keywordModels: any[],
  };
  measures: Measure[];
  updateGeneralInformation: (newData: Partial<RiskCaseOverviewProps['generalInformation']>) => void;
  updateMeasures: (newMeasures: Measure[]) => void;
}

const RiskCaseOverview: React.FC<RiskCaseOverviewProps> = ({
  generalInformation,
  measures,
  updateGeneralInformation,
  updateMeasures,
}) => {

  // const [formData, setFormData] = useState<any>({
  //   id: 0,
  //   riskCaseName: '',
  //   suppliers: [],
  //   ownerId: null,
  //   dateAdded: new Date(),
  //   isActive: false,
  //   description: '',
  //   keywordModels: [],
  //   subscribers: [],
  // });
  const [suppliers, setSuppliers] = useState<SelectBoxOption[] | null>(null);

  // const [measures, setMeasures] = useState<Measure[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');



  const { id } = useParams();
  const allSuppliers = useSelector(
    (state: any) => state.suppliers.allSuppliers
  );
  const location = useLocation();
  const isArchived = location.pathname.includes('archived');
  const dispatch = useDispatch();
  const riskCaseName = useSelector((state: any) => state.riskCases.currentRiskCaseName);

  useEffect(() => {
    setIsLoading(false);
  }, [generalInformation, measures]);

  const numericId = Number(id);

  const formatKwm = (kwmData: any[]) => {
    return kwmData.map(item => 
      typeof item === 'object' ? item : { id: item, label: '' }
    );
  };
  
  const fetchRiskCase = async () => {
    try {
      setIsLoading(true);
      const data = await RiskCaseApi.getRiskCaseById(numericId);
      updateMeasures(data.measures.riskMeasures);

      const formattedSuppliers = data?.generalInformation?.suppliers
      ? [].concat(...data.generalInformation.suppliers) // Flattening nested arrays
      : [];
      
      updateGeneralInformation({
        riskCaseId: data?.generalInformation?.riskCaseId,
        riskCaseName: data?.generalInformation?.riskCaseName,
        suppliers: formattedSuppliers,
        owner: data?.generalInformation?.owner,
        dateAdded: data?.generalInformation.dateAdded,
        isActive: data?.generalInformation.isActive,
        description: data?.generalInformation.description,
        keywordModels: formatKwm(data?.generalInformation.keywordModels),
        subscribers: data?.generalInformation.subscribers,
      });
    } catch (error) {
      console.error('Error fetching risk case:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchRiskCase();
  }, []);
  
  // const handleSave = async () => {
  //   if (!id) {
  //     console.error('No valid ID found');
  //     return;
  //   }

  //   const numericId = Number(id);
  //   if (isNaN(numericId)) {
  //     console.error('ID is not a valid number');
  //     return;
  //   }

  //   try {
  //     const body = {
  //       generalInformation: {
  //         riskCaseId: formData.id,
  //         dateAdded: formData.dateCreated || new Date().toISOString(),
  //         dateUpdated: new Date().toISOString(),
  //         dateDeleted: null,
  //         isActive: !!formData.isActive,
  //         isDeleted: false,
  //         riskCaseName: formData.riskCaseName,
  //         comments: formData.comments,
  //         description: formData.description,
  //         owner: formData.owner
  //           ? {
  //               key: formData.owner.key,
  //               name: formData.owner.name || "",
  //             }
  //           : null,
  //         subscribers: Array.isArray(formData.subscribers)
  //           ? formData.subscribers.map((subscriber: any) => {
  //               if (typeof subscriber === "object" && subscriber !== null)
  //                 return subscriber;
  //               return {
  //                 key: subscriber,
  //                 name: "",
  //               };
  //             })
  //           : [],
  //         suppliers: Array.isArray(formData.suppliers)
  //           ? formData.suppliers.map((supplier: any) => {
  //               if (typeof supplier === "object" && supplier !== null) {
  //                 return {
  //                   id: supplier.id,
  //                   label: supplier.label || "",
  //                 };
  //               }
  //               const foundSupplier = suppliers?.find((s) => s.id === supplier);
  //               return {
  //                 id: supplier,
  //                 label: foundSupplier?.label || "",
  //               };
  //             })
  //           : [],
  //         keywordModels: Array.isArray(formData.kwm)
  //           ? formData.kwm.map((kwm: any) => ({
  //               id: kwm.id,
  //               label: kwm.label,
  //             }))
  //           : [],
  //       },
  //       measures: {
  //         riskMeasures: measures,
  //       },
  //     };

  //     const updatedRiskCase = await RiskCaseApi.updateRiskCase(body);
  //     if (riskCaseName !== formData.riskCaseName) {
  //       dispatch(setRiskCurrentCaseName(updatedRiskCase.riskCaseName));
  //       dispatch(updateRiskCaseName({
  //         id: updatedRiskCase.id,
  //         newName: updatedRiskCase.riskCaseName
  //       }));
  //     }
  //     setSnackbarMessage('Risk case updated successfully');
  //     setSnackbarSeverity('success');
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error('Error saving risk case:', error);
  //     setSnackbarMessage('Error updating risk case');
  //     setSnackbarSeverity('error');
  //     setSnackbarOpen(true);
  //   }
  // };
  const handleArchived = () => {};

  // const handleEdit = (updatedMeasure: any) => {
  //   setMeasures((prevMeasures: any) =>
  //     prevMeasures.map((measure: any) =>
  //       measure.riskMeasureId === updatedMeasure.riskMeasureId
  //         ? updatedMeasure
  //         : measure
  //     )
  //   );
  // };

  const handleEdit = (updatedMeasure: Partial<Measure>) => {
    const updatedMeasures = measures.map((measure) =>
      measure.riskMeasureId === updatedMeasure.riskMeasureId 
        ? { ...measure, ...updatedMeasure }
        : measure
    );
    updateMeasures(updatedMeasures);
  };
  

  return (
    <div className="overview-container">
      <GeneralInformationRiskCase
        // isLoading={isLoading}
        // formData={formData}
        // setFormData={setFormData}
        isLoading={isLoading}
        formData={generalInformation}
        setFormData={updateGeneralInformation}

      />
      <h3 className="measures-title" style={{ paddingLeft: '20px' }}>Measures</h3>
      <MeasuresTableRiskCase measures={measures} onEdit={handleEdit} />
      <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
        {/* <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button> */}
        
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </div>
  );
};

export default RiskCaseOverview;
