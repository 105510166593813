import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
	DataGrid,
	GridToolbar,
	GridRowSelectionModel,
	GridCallbackDetails,
} from "@mui/x-data-grid";
import {
	Box,
	Snackbar,
	SnackbarCloseReason,
	SnackbarContent,
} from "@mui/material";
import {
	ResponseDto,
	NsnQueryDto,
	NsnSearchResponseDto,
	NsnSearchResultsDto,
} from "../../../api/types/types";
import { createColumn } from "../../../utils/data-grid";
import NsnApi from "../../../api/nsn/nsnAPI";

const initalData: NsnSearchResultsDto[] = [];

// Create column definitions
const columns = [
	{ ...createColumn("cage", "CAGE"), width: 70 },
	{ ...createColumn("cageName", "CAGE Name"), flex: 1 },
	{ ...createColumn("part", "Part Number"), minWidth: 150 },
	{ ...createColumn("nsn", "NSN"), width: 150 },
	{ ...createColumn("itemName", "Item Name"), flex: 1 },
	{ ...createColumn("rncc", "RNCC"), width: 50 },
	{ ...createColumn("rnvc", "RNVC"), width: 50 },
	{ ...createColumn("rnsc", "RNSC"), width: 50 },
	{ ...createColumn("amscG", "AMSC-G"), width: 80 },
	{
		...createColumn("dtNiinAsgmt", "DT NIIN ASGMT"),
		width: 130,
		flex: 0,
	},
];

export default function NsnSearchResultsPage() {
	// Get data from Location
	const location = useLocation();
	const locState = location.state;
	const query = locState?.query as NsnQueryDto;

	const navigate = useNavigate();

	// Config States
	const [snackBarState, setSnackBarState] = useState({
		open: false,
		message: "",
	});
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: initalData,
		total: 0,
		page: 1,
		pageSize: 50,
	});

	useEffect(() => {
		/**
		 * Displays error message
		 * @param message
		 * @param error
		 */
		const showError = (message: string, error?: any) => {
			setPageState((oldState) => ({
				...oldState,
				isLoading: false,
			}));

			if (error) {
				console.error(message, error);
			}
			setSnackBarState({
				open: true,
				message: message,
			});
		};

		/**
		 * / Fetch new when new page or new page size.
		 */
		const fetchData = async () => {
			setPageState((oldState) => ({ ...oldState, isLoading: true }));
			const newQuery = {
				...query,
				page: pageState.page,
				rowsPerPage: pageState.pageSize,
			};

			try {
				// Get paginated data from the server.
				const response: ResponseDto<NsnSearchResponseDto> =
					await NsnApi.searchFull(newQuery);

				if (response.isSuccess === true) {
					setPageState((oldState) => ({
						...oldState,
						isLoading: false,
						data: response.result?.results,
						total: response.result?.totalRows,
					}));
				} else {
					showError(response.message);
				}
			} catch (error) {
				showError("Error sending query!", error);
			}
		};

		fetchData();
	}, [pageState.page, pageState.pageSize, query]);

	/**
	 * Closes the validation SnackBar
	 * @param {*} event
	 * @param {*} reason
	 * @returns
	 */
	const handleSnackBarClose = (
		event: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason
	) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarState({ open: false, message: "" });
	};

	return (
		<>
			<Box className="page-container">
				<h4 className="page-title">Search Results</h4>
				<DataGrid
					autoHeight
					density="compact"
					getRowId={(row) => row.id * Math.random()}
					rows={pageState.data}
					rowCount={pageState.total}
					loading={pageState.isLoading}
					pageSizeOptions={[10, 50, 100]}
					paginationModel={{
						page: pageState.page - 1,
						pageSize: pageState.pageSize,
					}}
					paginationMode="server"
					onPaginationModelChange={({ page, pageSize }) =>
						setPageState((oldState) => ({
							...oldState,
							page: page + 1,
							pageSize: pageSize,
						}))
					}
					onRowSelectionModelChange={async (
						rowSelectionModel: GridRowSelectionModel,
						details: GridCallbackDetails
					) => {
						const row = details.api.getRow(rowSelectionModel[0]);

						navigate("/scrm/nsn/details", {
							state: {
								nsn: row.nsn,
							},
						});
					}}
					columns={columns}
					slots={{
						toolbar: GridToolbar,
					}}
				/>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={snackBarState.open}
				autoHideDuration={3000}
				onClose={handleSnackBarClose}
				//key={snackBarState.message}
			>
				<SnackbarContent
					style={{
						backgroundColor: "red",
						fontWeight: "bold",
					}}
					message={snackBarState.message}
				/>
			</Snackbar>
		</>
	);
}
