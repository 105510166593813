// @ts-ignore: Implicit any module
import httpClient from '../../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../../config';
import { ResponseDto, RiskMeasureDto, CreateRiskMeasureDto, UpdateRiskMeasureDto, CreateRiskMeasureLevelDto } from '../../types/types';

const { ALTRUITY_API_URL } = config;

// Base API path for RiskMeasures
const RISK_MEASURES_API_PATH = `${ALTRUITY_API_URL}/Admin/RiskMeasures`;

const RiskMeasures = {
    getAllRiskMeasures: async (): Promise<RiskMeasureDto[]> => {
        const { data } = await httpClient.get<ResponseDto<RiskMeasureDto[]>>(`${RISK_MEASURES_API_PATH}/RiskMeasure`);
        return data.result;
    },
    getRiskMeasureById: async (riskMeasureId: number): Promise<RiskMeasureDto> => {
        const { data } = await httpClient.get<ResponseDto<RiskMeasureDto>>(`${RISK_MEASURES_API_PATH}/RiskMeasure/${riskMeasureId}`);
        return data.result;
    },
    createRiskMeasure: async (request: CreateRiskMeasureDto): Promise<ResponseDto<RiskMeasureDto>> => {
        const { data } = await httpClient.post<ResponseDto<RiskMeasureDto>>(`${RISK_MEASURES_API_PATH}/RiskMeasure`, request);
        return data;
    },
    updateRiskMeasure: async (riskMeasureId: number, request: UpdateRiskMeasureDto): Promise<RiskMeasureDto> => {
        const { data } = await httpClient.put<ResponseDto<RiskMeasureDto>>(`${RISK_MEASURES_API_PATH}/RiskMeasure/${riskMeasureId}`, request);
        return data.result;
    },
    deleteRiskMeasure: async (riskMeasureId: number): Promise<RiskMeasureDto> => {
        const { data } = await httpClient.delete<ResponseDto<RiskMeasureDto>>(`${RISK_MEASURES_API_PATH}/RiskMeasure/${riskMeasureId}`);
        return data.result;
    },
    updateRiskMeasureLevel: async (riskMeasureId: number, request: CreateRiskMeasureLevelDto): Promise<RiskMeasureDto> => {
        const { data } = await httpClient.put<ResponseDto<RiskMeasureDto>>(`${RISK_MEASURES_API_PATH}/RiskMeasureLevel/${riskMeasureId}`, request);
        return data.result;
    }
};

export default RiskMeasures;