import KeywordModel from "./keywordModel";
import Subscribers from "./subscribers";


const KeywordModelAPI = {
  ...KeywordModel,
  ...Subscribers,
};

export default KeywordModelAPI;
