import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AllOwners {
  id: number,
  label: string,
}


const initialState = {
  owners: [] as AllOwners[],
}

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers:{
    addOwners: (state, action: PayloadAction<AllOwners[]>) => {
      state.owners = [...state.owners, ...action.payload];
    },
  },
});


export const { addOwners} = permissionsSlice.actions;
export default permissionsSlice.reducer;