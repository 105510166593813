import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
	Button,
	TextField,
	FormGroup,
	Box,
	Grid,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	SnackbarCloseReason,
} from "@mui/material";
import {
	AltruityCheckbox,
	AltruityTextField,
	HelpIcon,
	textAreaStyles,
	ValidationSnackbar,
	// @ts-ignore: Implicit any module
} from "../../../components/form";
// @ts-ignore: Implicit any module
import informationPng from "../../../assets/information.png";
// @ts-ignore: Implicit any module
import { isNullOrWhitespace } from "../../../utils/validation-helpers.js";
import { NsnQueryDto } from "../../../api/types/types";
import {
	niinHelp,
	cageHelp,
	partHelp,
	sosHelp,
	amscHelp,
	querySyntaxHelp,
	// @ts-ignore: Implicit any module
} from "./help-content";
import "./index.scss";

export default function NsnPage() {
	const navigate = useNavigate();

	// For state
	const [formData, setFormData] = useState<NsnQueryDto>({
		nsn: "",
		cage: "",
		part: "",
		doSosOnly: false,
		doAmscgOnly: false,
		doFullContent: false,
		keywordSearch: "",
		rowsPerPage: 50,
	});

	// Validation snackbar state
	const [snackBarState, setSnackBarState] = useState({
		open: false,
		message: "",
	});

	/**
	 * Closes the validation SnackBar
	 * @param {*} event
	 * @param {*} reason
	 * @returns
	 */
	const handleSnackBarClose = (
		event: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason
	) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarState({ open: false, message: "" });
	};

	/***
	 * Updates the form data state
	 */
	function handleFormFieldChange(e: any) {
		var { name, value } = e.target;

		// Convert to boolean value.
		if (value === "true" || value === "false") {
			value = value === "true";
		}

		// Use checkbox 'checked' prop to determine value.
		if (e.target.type === "checkbox") {
			value = e.target.checked ? true : false;
		}

		setFormData((prevData) => ({ ...prevData, [name]: value }));
	}

	/**
	 * Submits search parameters
	 */
	async function handleSubmit() {
		if (
			isNullOrWhitespace(formData.nsn) &&
			isNullOrWhitespace(formData.cage) &&
			isNullOrWhitespace(formData.part) &&
			isNullOrWhitespace(formData.keywordSearch)
		) {
			setSnackBarState({
				open: true,
				message: "No Search Criteria Provided!",
			});
			return;
		}

		navigate("/scrm/nsn/results", {
			state: {
				query: formData,
			},
		});
	}

	return (
		<>
			<Box className="nsn-query-container page-container">
				<h4 className="page-title">NSN Query</h4>
				<Grid container>
					<Grid item xs={12} sm={6} style={{ minWidth: "500px" }}>
						<Grid
							container
							direction="column"
							rowSpacing={1}
							className="rcorners"
							style={{
								width: "98%",
								maxWidth: "500px",
								margin: "auto",
								marginTop: "20px",
							}}
							sx={{
								marginTop: "0px",
								marginBottom: "0px",
							}}
						>
							<Grid
								item
								xs={12}
								style={{
									fontSize: "20px",
									paddingTop: "10px",
									paddingBottom: "10px",
									color: "black",
									textAlign: "center",
								}}
							>
								<b>Enter FLIS Search Criteria</b>
							</Grid>

							<Grid item>
								<AltruityTextField
									label="NSN/NIIN"
									width="50%"
									onChange={handleFormFieldChange}
									fullWidth
									id="nsn"
									name="nsn"
									helpConent={niinHelp}
								/>
							</Grid>
							<Grid item>
								<AltruityTextField
									label="Part Number"
									width="50%"
									onChange={handleFormFieldChange}
									fullWidth
									id="part"
									name="part"
									helpConent={partHelp}
								/>
							</Grid>
							<Grid item>
								<AltruityTextField
									label="Cage Code"
									width="20%"
									onChange={handleFormFieldChange}
									id="cage"
									name="cage"
									helpConent={cageHelp}
								/>
							</Grid>
							<Grid item>
								<FormGroup>
									<AltruityCheckbox
										label="Include only Source of Supply parts"
										name="doSosOnly"
										value={true}
										onChange={handleFormFieldChange}
										hintPopup={sosHelp}
									/>
									<AltruityCheckbox
										label="Include only NSNs that have an
												AMSC of 'G'"
										name="doAmscgOnly"
										value={true}
										onChange={handleFormFieldChange}
										hintPopup={amscHelp}
									/>
								</FormGroup>
							</Grid>
							<Grid item>
								<Box
									display="flex"
									className="typography-container"
								>
									<Typography>
										Item Name Keyword(s)
									</Typography>
									<HelpIcon label="Keyword Search Syntax">
										{querySyntaxHelp}
									</HelpIcon>
								</Box>
								<RadioGroup
									row
									name="doFullContent"
									onChange={handleFormFieldChange}
								>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label=" Item Name & Characteristics"
									/>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label="Item Name Only"
									/>
								</RadioGroup>
								<TextField
									id="keywordSearch"
									name="keywordSearch"
									fullWidth
									multiline
									rows={4}
									onChange={handleFormFieldChange}
									InputProps={{
										style: textAreaStyles,
									}}
								/>
							</Grid>
							<Grid item>
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<Button
										variant="contained"
										onClick={handleSubmit}
									>
										GO
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={6}>
						<p>
							NSN Query is provided as a service to support the
							Procurement, Maintenance and Support of Government
							supply items. It utilizes the Public Federal
							Logistics Information System Data made available by
							the Defense Logistics Agency.
						</p>
						<p>
							Please note that NSN Query is <b>NOT</b> affiliated
							with the U.S. Government.
						</p>
						<p>
							For an explantion of each of the Query fields, click
							on the <img src={informationPng} alt="Help icon" />{" "}
							icon next to each field.
						</p>
						<p>
							For a description of the Results in the Output
							Result tables, click on the column heading.
						</p>
						<p>
							To see the Cage detail for a part, click on the cage
							code in the Reference/Part Number Table.
						</p>
						<p>
							Lines in <b>Bold</b> in the Reference/Part Number
							Table are considered the Source of Supply, based on
							the RNCC and RNVC values.
						</p>
					</Grid>
				</Grid>
			</Box>
			<ValidationSnackbar
				open={snackBarState.open}
				message={snackBarState.message}
				onClose={handleSnackBarClose}
			/>
		</>
	);
}
