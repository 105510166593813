import React from 'react';
import { Accordion } from 'devextreme-react';
import './index.scss';
import Assessment from './assessments';
import Financials from './financials';
import Filings from './filings';
import Information from './information';
import FamilyTree from './family-tree';
import Principals from './principals';


const DNB_SECTIONS = [
  { id: '1', description: 'Information' }, 
  { id: '2', description: 'Assessment' }, 
  // { ID: 3, description: 'Hurricane' },
  // { ID: 4, description: "Ownership" },
  { id: '5', description: "Family Tree" },
  { id: '6', description: "Filings" },
  { id: '7', description: "Financials" },
  { id: '8', description: "Principals"}
];

function CustomTitle(data) {
  return <div className="accordion-header">{data.description}</div>;
}

function CustomItem(data) {
  switch (data.description) {
    case "Information":
      return <Information />
    case "Assessment":
      return <Assessment />
    case "Family Tree":
      return <FamilyTree />
    case "Filings":
      return <Filings />
    case "Financials":
      return <Financials />
    case "Principals":
      return <Principals />
    default:
      return <div>{data.description}</div>;
  }
}

const DBTab = () => {

  return (
    <div>
      <Accordion
        dataSource={DNB_SECTIONS}
        collapsible={true}
        multiple={true}
        animationDuration={300}
        itemTitleRender={CustomTitle}
        itemRender={CustomItem}
        id="accordion-container"
      />
    </div>
  )
}

export default DBTab;