import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface RiskCaseAccordionProps {
  dataSource: any[];
  itemTitleRender: (item: any) => JSX.Element;
  itemRender: (item: any) => JSX.Element;
  id: string;
}

const RiskCaseAccordion: React.FC<RiskCaseAccordionProps> = ({
  dataSource,
  itemTitleRender,
  itemRender,
  id,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>("panel0");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div id={id}>
      {dataSource.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            {itemTitleRender(item)}
          </AccordionSummary>
          <AccordionDetails>{itemRender(item)}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default RiskCaseAccordion;
