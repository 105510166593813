import { Accordion } from "devextreme-react";
import "./index.scss";
import GeneralInformation from "./general-information";
import Names from "./names";
import Locations from "./locations";
import Industries from "./industries";
import RelatedSuppliersTable from "./related-suppliers-table";
import { useParams } from "react-router-dom";

function CustomTitle(data) {
  return <div className="accordion-header">{data.Description}</div>;
}

const CustomItem = (data) => data.Component;

const SupplierSetupTab = () => {
  const { id } = useParams();

  const isNumber = !isNaN(parseInt(id));

  const SUPPLIER_SETUP_SECTIONS = [
    { Description: "General Information", Component: <GeneralInformation /> },
    ...(isNumber
      ? [
          { Description: "Names", Component: <Names /> },
          { Description: "Locations", Component: <Locations /> },
          {
            Description: "Subsidiaries",
            Component: (
              <RelatedSuppliersTable
                entityName="subsidiary"
                relationTypeId={4}
              />
            ),
          },
          {
            Description: "Competitors",
            Component: (
              <RelatedSuppliersTable
                entityName="competitor"
                relationTypeId={8}
              />
            ),
          },
          {
            Description: "Customers",
            Component: (
              <RelatedSuppliersTable entityName="customer" relationTypeId={7} />
            ),
          },
          { Description: "Industries", Component: <Industries /> },
          {
            Description: "Suppliers",
            Component: (
              <RelatedSuppliersTable entityName="supplier" relationTypeId={2} />
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <Accordion
        dataSource={SUPPLIER_SETUP_SECTIONS}
        collapsible={true}
        multiple={true}
        animationDuration={300}
        itemTitleRender={CustomTitle}
        itemRender={CustomItem}
        id="accordion-container"
      />
    </div>
  );
};

export default SupplierSetupTab;
