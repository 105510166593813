import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import KeywordModelAPI from '../../api/keywordModel/keywordModelApi';
import KeywordModelForm from '../../components/risk-Models/risk-models-form/RiskModelsForm';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedKeywordModel } from '../../slices/keywordModelSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { Accordion } from 'devextreme-react';
import './index.scss';

interface KeywordModelData {
  id: number;
  modelName: string;
}

interface KeywordModelState {
  allKeywordModel: KeywordModelData[];
  selectedKeywordModel: KeywordModelData | null;
}

interface RootState {
  keywordModel: KeywordModelState;
}

const DNB_SECTIONS = [
  { id: '1', description: 'Keyword Model' },
  { id: '2', description: 'Assessment' },
  { id: '3', description: 'Family Tree' },
  { id: '4', description: 'Filings' },
  { id: '5', description: 'Financials' },
  { id: '6', description: 'Principals' },
];

function CustomTitle(data: any) {
  return <div className="accordion-header">{data.description}</div>;
}

function CustomItem(data: any) {
  switch (data.description) {
    case 'Keyword Model':
      return <KeywordModelForm />;
    case 'Assessment':
      return <div>Assessment content goes here.</div>;
    case 'Family Tree':
      return <div>Family Tree content goes here.</div>;
    case 'Filings':
      return <div>Filings content goes here.</div>;
    case 'Financials':
      return <div>Financials content goes here.</div>;
    case 'Principals':
      return <div>Principals content goes here.</div>;
    default:
      return <div>{data.description}</div>;
  }
}

const RiskModels: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const keywordModelData = useSelector(
    (state: RootState) => state.keywordModel.selectedKeywordModel
  );
  const fetchData = async () => {
    try {
      if (id) {
        const data = await KeywordModelAPI.getKeywordById(Number(id));
        dispatch(
          addSelectedKeywordModel({
            selectedKeywordModel: {
              id: data.id,
              modelName: data.modelName,
            },
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="risk-models-page-container" key={id}>
      <h4 className="risk-model-title">
        Keyword Model {keywordModelData?.modelName}
      </h4>
      <Accordion
        dataSource={DNB_SECTIONS}
        collapsible={true}
        multiple={true}
        animationDuration={300}
        itemTitleRender={CustomTitle}
        itemRender={CustomItem}
        id="accordion-container"
      />
    </div>
  );
};

export default RiskModels;
