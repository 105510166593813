import React, { useState, useEffect } from 'react';
import { ChangeEvent } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TextField,
  Button,
  SelectChangeEvent,
  Box,
} from '@mui/material';

interface RiskMeasureLevel {
  id: number;
  label: string;
}

interface Measure {
  riskMeasureId: number;
  riskMeasureName: string;
  isRequired?: boolean;
  availableRiskMeasureLevels: RiskMeasureLevel[];
  selectedLevelId?: number;
  selectedSeverity?: string;
  selectedComment?: string;
  comments?: string;
}

interface MeasuresTableProps {
  measures: Measure[];
  onEdit: (measure: Measure) => void;
}

const MeasuresTable: React.FC<MeasuresTableProps> = ({ measures = [], onEdit }) => {
  const [rows, setRows] = useState<Measure[]>([]);
  const [editRowId, setEditRowId] = useState<number | null>(null);

  useEffect(() => {
    setRows(measures.map(measure => ({
      ...measure,
      selectedSeverity: measure.selectedSeverity || '',
      selectedComment: measure.comments || '',
    })));
  }, [measures]);

  const handleSeveritySelection = (event: SelectChangeEvent<string>, rowId: number) => {
    const newSeverity = event.target.value;
    setRows(prevRows =>
      prevRows.map(row => {
        if (row.riskMeasureId === rowId) {
          const selectedLevel = row.availableRiskMeasureLevels.find(
            level => level.label === newSeverity
          );
          const updatedRow = {
            ...row,
            selectedSeverity: newSeverity,
            selectedLevelId: selectedLevel?.id,
          };
          onEdit(updatedRow);
          return updatedRow;
        }
        return row;
      })
    );
  };

  const handleCommentChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowId: number) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.riskMeasureId === rowId
          ? { ...row, selectedComment: event.target.value }
          : row
      )
    );
  };

  const handleSaveClick = (row: Measure) => {
    setEditRowId(null);
    const updatedRow = {
      ...row,
      comments: row.selectedComment || '',
      selectedLevelId: row.availableRiskMeasureLevels.find(
        level => level.label === row.selectedSeverity
      )?.id,
    };
    setRows(prevRows =>
      prevRows.map(r =>
        r.riskMeasureId === row.riskMeasureId ? updatedRow : r
      )
    );
    onEdit(updatedRow);
  };

  const sortedRiskMeasureLevels = (levels: RiskMeasureLevel[]) => {
    return levels.sort((a, b) => {
      const aNum = parseInt(a.label.split('-')[0].trim());
      const bNum = parseInt(b.label.split('-')[0].trim());
      return aNum - bNum;
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="30%">Measure</TableCell>
            <TableCell width="30%">Severity</TableCell>
            <TableCell width="30%">Comments</TableCell>
            <TableCell width="10%">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.riskMeasureId}>
              <TableCell>{row.riskMeasureName}</TableCell>
              <TableCell>
                {editRowId === row.riskMeasureId ? (
                  <Box sx={{ minWidth: 120, maxWidth: '100%' }}>
                    <Select
                      value={row.selectedSeverity || ''}
                      onChange={(e) => handleSeveritySelection(e, row.riskMeasureId)}
                      fullWidth
                      size="small"
                      sx={{
                        fontSize: '0.875rem',
                      }}
                    >
                      {sortedRiskMeasureLevels(row.availableRiskMeasureLevels).map((level) => (
                        <MenuItem key={level.id} value={level.label} sx={{ fontSize: '0.875rem', padding: '6px 16px' }}>
                          {level.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : (
                  row.selectedSeverity || 'No severity selected'
                )}
              </TableCell>
              <TableCell>
                {editRowId === row.riskMeasureId ? (
                  <TextField
                    value={row.selectedComment}
                    onChange={(e) => handleCommentChange(e, row.riskMeasureId)}
                    fullWidth
                    size="small"
                    multiline
                    maxRows={4}
                  />
                ) : (
                  row.selectedComment || ''
                )}
              </TableCell>
              <TableCell>
                {editRowId === row.riskMeasureId ? (
                  <Button onClick={() => handleSaveClick(row)}>
                    Save
                  </Button>
                ) : (
                  <Button onClick={() => setEditRowId(row.riskMeasureId)}>
                    Edit
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MeasuresTable;