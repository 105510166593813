import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import RiskCaseApi from "../../../../api/riskCase/riskCaseApi";
import { RiskCaseDto, UserDto } from "../../../../api/types/types";

const RiskCasesGrid = () => {
  const [riskCases, setRiskCases] = useState<RiskCaseDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleViewClick = (riskCase: RiskCaseDto) => {};

  const getRiskCases = async () => {
    try {
      setLoading(true);
      const data = await RiskCaseApi.getAllRiskCasesForGridView();
      setRiskCases(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRiskCases();
  }, []);

  const formatDate = (dateString: string | null) =>
    dateString ? new Date(dateString).toLocaleDateString("en-US") : "";

  const columns: GridColDef[] = [
    { field: "riskCaseName", headerName: "Risk Case Name", flex: 1 },
    {
      field: "dateAdded",
      headerName: "Date Created",
      flex: 1,
      valueGetter: (dateAdded: string) => formatDate(dateAdded),
    },
    {
      field: "dateUpdated",
      headerName: "Date Last Activity",
      flex: 1,
      valueGetter: (dateUpdated: string) => formatDate(dateUpdated),
    },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
      valueGetter: (owner: UserDto) => owner?.fullName,
    },
    {
      field: "riskCaseSuppliers",
      headerName: "Suppliers",
      flex: 1,
      valueGetter: (suppliers: string) => suppliers,
    },
    { field: "probability", headerName: "Probability", flex: 1 },
    { field: "magnitude", headerName: "Magnitude", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "right",
      align: "right",
      flex: 0.1,
      minWidth: 120,
      renderCell: (params) => (
        <Button
          variant="text"
          color="primary"
          onClick={() => handleViewClick(params.row)}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <DataGrid
      rows={riskCases}
      columns={columns}
      getRowId={(row) => row.id}
      loading={loading}
      autoHeight
    />
  );
};

export default RiskCasesGrid;
