import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Box, LinearProgress, SnackbarCloseReason } from "@mui/material";
import NsnApi from "../../../api/nsn/nsnAPI";
import { NsnDetailsDto, ResponseDto } from "../../../api/types/types";
import "./details.scss";
import {
	NsnDetailHeader,
	NsnDetailIdDataGrid,
	NsnDetailRefPnDataGrid,
	NsnDetailStandardizationDataGrid,
	NsnDetailMoeDataGrid,
	NsnDetailManagementDataGrid,
	NsnDetailPhraseDataGrid,
	NsnDetailFreightDataGrid,
	NsnDetailSolicitationsDataGrid,
	NsnDetailCharacteristicsDataGrid,
	// @ts-ignore: Implicit any module
} from "../../../components/nsn";
// @ts-ignore: Implicit any module
import { ValidationSnackbar } from "../../../components/form";

const initalData: NsnDetailsDto | null = null;

export default function NsnDetailsPage() {
	const location = useLocation();
	const locState = location.state;
	const nsn = locState?.nsn as string;

	// Config states
	const [pageState, setPageState] = useState({
		isLoading: true,
		data: initalData,
	});

	const [snackBarState, setSnackBarState] = useState({
		open: false,
		message: "",
	});

	/**
	 * Closes the validation SnackBar
	 * @param {*} event
	 * @param {*} reason
	 * @returns
	 */
	const handleSnackBarClose = (
		event: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason
	) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarState({ open: false, message: "" });
	};

	/**
	 * Displays error message
	 * @param message
	 * @param error
	 */
	const showError = (message: string, error?: any) => {
		setPageState((oldState) => ({
			...oldState,
			isLoading: false,
		}));

		if (error) {
			console.error(message, error);
		}
		setSnackBarState({
			open: true,
			message: message,
		});
	};

	useEffect(() => {
		/**
		 * / Fetch new data on initial component load
		 */
		async function fetchData() {
			setPageState((oldState) => ({ ...oldState, isLoading: true }));

			try {
				// Get paginated data from the server.
				const response: ResponseDto<NsnDetailsDto> =
					await NsnApi.getNsnDetails(nsn);

				if (response.isSuccess === true) {
					setPageState((oldState) => ({
						...oldState,
						isLoading: false,
						data: response.result,
					}));
				} else {
					showError(response.message);
				}
			} catch (error) {
				showError("Error sending query!", error);
			}
		}

		fetchData();
	}, [nsn]);

	return (
		<>
			<Box className="page-container">
				<h4 className="page-title">Detail</h4>
				{pageState.isLoading ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						sx={{ width: "100%" }}
					>
						<LinearProgress sx={{ width: "100%" }} />
					</Box>
				) : (
					<Box>
						<NsnDetailHeader
							headerData={pageState.data}
							nsn={nsn}
						/>
						<NsnDetailIdDataGrid data={pageState.data?.ivmList} />
						<NsnDetailRefPnDataGrid
							data={pageState.data?.refPartList}
						/>
						<NsnDetailStandardizationDataGrid
							data={pageState.data?.itStdList}
						/>
						<NsnDetailMoeDataGrid
							data={pageState.data?.moeRuleList}
						/>
						<NsnDetailManagementDataGrid
							data={pageState.data?.managementList}
						/>
						<NsnDetailPhraseDataGrid
							data={pageState.data?.phraseList}
						/>
						<NsnDetailFreightDataGrid
							data={pageState.data?.freightList}
						/>
						<NsnDetailSolicitationsDataGrid
							data={pageState.data?.solicitationList}
						/>
						<NsnDetailCharacteristicsDataGrid
							data={pageState.data?.characteristicsList}
						/>
					</Box>
				)}
			</Box>
			<ValidationSnackbar
				open={snackBarState.open}
				message={snackBarState.message}
				onClose={handleSnackBarClose}
			/>
		</>
	);
}
