import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel, Switch } from "@mui/material";
import { CreateRiskMeasureDto } from "../../../api/types/types";

interface RiskMeasureCreationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (form: any) => void;
}

export default function RiskMeasureCreationDialog({
  open,
  handleSubmit,
  handleClose,
}: RiskMeasureCreationDialogProps) {
  const defaultRiskMeasure: CreateRiskMeasureDto = {
    riskMeasureName: "",
    description: null,
    isRequired: false,
    levelLabels: null,
  };

  const [form, setForm] = useState<CreateRiskMeasureDto>({
    ...defaultRiskMeasure,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setForm((prevForm: any) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const onClose = () => {
    setForm({
      ...defaultRiskMeasure,
    });
    handleClose();
  };

  return (
    <Dialog className="supplier-popup" open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Risk Measure</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Risk Measure Name"
          type="text"
          name="riskMeasureName"
          variant="standard"
          value={form.riskMeasureName}
          fullWidth
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Switch
              name="isRequired"
              checked={form.isRequired}
              onChange={handleChange}
            />
          }
          label="Is Required?"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSubmit({ ...form });
            onClose();
          }}
          variant="contained"
          color="primary"
        >
          Create Risk Measure
        </Button>
        <Button
          onClick={onClose}
          type="submit"
          variant="outlined"
          color="inherit"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
