import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Alert,
  Button,
  Checkbox,
  Snackbar,
  SnackbarCloseReason,
  TextField,
  Tooltip,
} from "@mui/material";
import "./RiskMeasuresTable.scss";
import RiskMeasuresAPI from "../../../api/admin/riskMeasures/riskMeasuresAPI";
import {
  CreateRiskMeasureLevelDto,
  RiskMeasureDto,
  RiskMeasureLevelDto,
  UpdateRiskMeasureDto,
} from "../../../api/types/types";
import DataGrid from "../../../components/data-grid/DataGrid";
import { createColumn } from "../../../utils/data-grid";
import AddIcon from "@mui/icons-material/Add";
import RiskMeasureCreationDialog from "./RiskMeasureCreationDialog";
import DeleteConfirmationDialog from "../../data-grid/DeleteConfirmationDialog";

interface SnackbarProps {
  open: boolean;
  severity: "error" | "success";
  message: string;
}

export default function RiskMeasuresTable() {
  const [riskMeasures, setRiskMeasures] = useState<RiskMeasureDto[]>([]);
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>();

  const handleClose = () => {
    setOpen(false);
  };

  const loadData = async () => {
    const riskMeasures = await RiskMeasuresAPI.getAllRiskMeasures();

    // sort the risk measures ascending by id
    const sortedRiskMeasures = riskMeasures.map(
      (riskMeasure: RiskMeasureDto) => ({
        ...riskMeasure,
        riskMeasureLevels: riskMeasure.riskMeasureLevels.sort(
          (a, b) => a.levelId - b.levelId
        ),
      })
    );

    setRiskMeasures(sortedRiskMeasures);
  };

  const updateInnerData = async (id: number, data: RiskMeasureLevelDto) => {
    const body: CreateRiskMeasureLevelDto = {
      ...data,
    };
    await RiskMeasuresAPI.updateRiskMeasureLevel(id, body);
  };

  const updateOuterData = async (id: number, data: RiskMeasureDto) => {
    const body: UpdateRiskMeasureDto = {
      ...data,
    };
    await RiskMeasuresAPI.updateRiskMeasure(id, body);
  };

  const deleteOuterData = async (id: number) => {
    await RiskMeasuresAPI.deleteRiskMeasure(id);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = async (form: any) => {
    try {
      const response = await RiskMeasuresAPI.createRiskMeasure(form);
      await loadData();
      setOpen(false);
      setSnackbar({
        open: true,
        severity: "success",
        message: response.message,
      });
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || "An error occurred";

      setSnackbar({
        open: true,
        severity: "error",
        message: errorMessage,
      });
    }
  };

  const closeSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={snackbar?.severity === "error" ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      <RiskMeasureCreationDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <div className="add-button">
        <Tooltip title="Add a row">
          <IconButton onClick={() => setOpen(true)}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
      <TableContainer className="table">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "1%" }} />
              <TableCell>Name</TableCell>
              <TableCell>Is Required?</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {riskMeasures.map((row) => (
              <Row
                key={row.id}
                row={row}
                loadData={loadData}
                updateHeaderData={updateOuterData}
                updateTableData={updateInnerData}
                deleteHeaderData={deleteOuterData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

interface RowProps {
  row: RiskMeasureDto;
  createTableData?: (data: any) => void;
  loadData?: () => void;
  updateHeaderData?: (id: number, data: any) => Promise<void>;
  updateTableData?: (id: number, data: any) => Promise<void>;
  deleteHeaderData?: (id: number) => Promise<void>;
  deleteTableData?: (id: number) => Promise<void>;
}

function Row({
  row,
  createTableData,
  loadData,
  updateHeaderData,
  updateTableData,
  deleteHeaderData,
  deleteTableData,
}: RowProps) {
  const [open, setOpen] = useState(false);

  const columns = [
    createColumn("levelId", "Level", undefined, true),
    createColumn("label", "Label", undefined, true),
    createColumn("monetary", "Monetary", undefined, true),
  ];

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {/* Header Row */}
        <HeaderRow
          row={row}
          updateHeaderData={updateHeaderData}
          loadData={loadData}
          deleteHeaderData={deleteHeaderData}
        />
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <DataGrid
                data={row.riskMeasureLevels}
                columns={columns}
                loadData={loadData}
                createData={createTableData}
                deleteData={deleteTableData}
                updateData={updateTableData}
                isDeletable={false}
                isCreatable={false}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface HeaderRowProps {
  row: RiskMeasureDto;
  updateHeaderData?: (id: number, data: any) => Promise<void>;
  loadData?: () => void;
  deleteHeaderData?: (id: number) => Promise<void>;
}

function HeaderRow({
  row,
  updateHeaderData,
  loadData,
  deleteHeaderData,
}: HeaderRowProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedHeaderData, setEditedHeaderData] = useState({
    riskMeasureName: row.riskMeasureName,
    isRequired: row.isRequired,
  });
  const [deleteRowId, setDeleteRowId] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const handleHeaderChange = (field: string, value: any) => {
    setEditedHeaderData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditHeader = () => {
    setIsEditing(true);
  };

  const handleSaveHeader = () => {
    if (updateHeaderData) {
      updateHeaderData(row.id, editedHeaderData).then(() => {
        if (loadData) loadData();
      });
    }
    setIsEditing(false);
  };

  const handleCancelHeader = () => {
    setIsEditing(false);
    setEditedHeaderData({
      riskMeasureName: row.riskMeasureName,
      isRequired: row.isRequired,
    });
  };

  const handleDeleteHeader = (id: number) => () => {
    setDeleteRowId(id);
    setOpenDeleteDialog(true);
  };

  const closeDialog = () => {
    setDeleteRowId(null);
    setOpenDeleteDialog(false);
  };

  const confirmDelete = async () => {
    if (deleteRowId != null && deleteHeaderData) {
      await deleteHeaderData(parseInt(deleteRowId.toString()));
      if (loadData) loadData();
      closeDialog();
    }
  };

  const cancelDelete = () => {
    closeDialog();
  };

  return (
    <React.Fragment>
      {/* Editable RiskMeasureName */}
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedHeaderData.riskMeasureName}
            onChange={(e) =>
              handleHeaderChange("riskMeasureName", e.target.value)
            }
          />
        ) : (
          row.riskMeasureName
        )}
      </TableCell>

      {/* Editable Checkbox */}
      <TableCell>
        {isEditing ? (
          <Checkbox
            checked={editedHeaderData.isRequired}
            onChange={(e) => handleHeaderChange("isRequired", e.target.checked)}
          />
        ) : (
          <Checkbox checked={row.isRequired} disabled={!isEditing} />
        )}
      </TableCell>

      <TableCell align="right">
        {isEditing ? (
          <>
            <Button className="button" onClick={handleSaveHeader}>
              Save
            </Button>
            <Button className="button" onClick={handleCancelHeader}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button
              className="button"
              onClick={handleEditHeader}
              disabled={row.isSystemLevel}
            >
              Edit
            </Button>
            <Button
              className="button"
              disabled={row.isSystemLevel}
              onClick={handleDeleteHeader(row.id)}
            >
              Delete
            </Button>
          </>
        )}
      </TableCell>

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
    </React.Fragment>
  );
}
