import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import "./SupplierPopup.scss";

interface IPopupFields {
  key: string;
  label: string;
}

interface SupplierPopupProps {
  visible: boolean;
  onClose: () => void;
  popupTitle: string;
  popupFields: IPopupFields[] | undefined;
  popupCreateLabel: string;
  newEntity: any;
  handleCreate: (data: any) => void;
  loadData: () => void;
}

const SupplierPopup: React.FC<SupplierPopupProps> = ({
  visible,
  onClose,
  popupTitle,
  popupFields,
  popupCreateLabel,
  newEntity,
  handleCreate,
  loadData,
}) => {
  const handleSubmit = async () => {
    if (!handleCreate) return;
    await handleCreate(newEntity);
    await loadData();
    onClose();
  };

  return (
    <Dialog
      className="supplier-popup"
      open={visible}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="supplier-popup-title"
      PaperProps={{
        style: {
          width: 500,
        },
      }}
    >
      <DialogTitle id="supplier-popup-title">{popupTitle}</DialogTitle>

      <DialogContent>
        {popupFields?.map((field, index) => (
          <Box key={field.key} mb={index ? 2 : 0}>
            <TextField
              fullWidth
              label={field.label}
              value={String(newEntity[field.key] || "")}
              variant="standard"
              size="small"
              disabled
            />
          </Box>
        ))}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {popupCreateLabel}
        </Button>
        <Button onClick={onClose} variant="outlined" color="inherit">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupplierPopup;
