import React, { useEffect, useState } from 'react';
import './RiskDiscoveryHitCollections.scss';
import Card from '../../card/Card';
import { useDispatch, useSelector } from 'react-redux';
import DataGridSupplierSetup, {
  IDataGridColumn,
} from '../../../components/data-grid/DataGridSupplierSetup';
import RiskDiscoverySelectBox from '../risk-discovery-select-box/RiskDiscoverySelectBox';
import ArticlesAPI from '../../../api/articles/articlesAPI';
import PopupArticle from '../risk-discovery-popup/PopupArticle';
import { useLocation, useParams } from 'react-router';
import {
  articleRiskCase,
  setCollections,
  setCollectionsLoading,
} from '../../../slices/hitsSlice';
import PopupRiskCases from '../popup-risk-cases/PopupRiskCases';
import { createColumns as createRiskDiscoveryColumns } from '../risk-discovery-hits/RiskDiscoveryHits';
import { Button } from '@mui/material';
import ActionId from '../consts/actions';
import RiskDiscoveryApi from '../../../api/riskDiscovery/riskDiscoverylAPI';
import { extractIds } from '../../../utils/data-grid';
import DiscoveryType from '../consts/discoveryType';
import { setRiskCase, setSnackbar } from '../../../slices/discoverySlice';
import RiskCasePopup from '../risk-case-popup/RiskCasePopup';
import RiskCaseApi from '../../../api/riskCase/riskCase';
import { RiskCaseCollection } from '../../../api/riskDiscovery/riskDiscovery';

interface Article {
  id: number;
  title: string;
  sourceDomain: string;
  positiveSentiment: number;
  negativeSentiment: number;
  suppliers: string;
  semanticSummary: string;
  url: string;
  sentimentMetrics: string;
  content: string;
  imageUrl: string;
  articleRiskCases?: any[];
}

interface Collection {
  id: number;
  collectionName: string;
  articleCount: number;
  avgSent: number;
  articles: Article[];
}

interface ArchivedKeywordModel {
  id: number;
  modelName?: string;
  items: any[];
}
interface ArchivedSupplierModel {
  id: number;
  name?: string;
}

interface KeywordModel {
  id: number;
  modelName: string;
  items: any[];
}

interface Supplier {
  id: number;
  name: string;
}

const createColumns = (
  handleViewClick: (id: number) => void,
  handleCreateRiskCase: (collectionId: number) => Promise<void>,
  getCollections: () => void
): IDataGridColumn[] => [
  {
    field: 'collectionName',
    headerName: 'Title',
    type: 'string',
    required: true,
  },
  {
    field: 'articleCount',
    headerName: 'Article Count',
    type: 'number',
    required: true,
  },
  {
    field: 'avgSent',
    headerName: 'Avg. Sent',
    type: 'number',
    required: true,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'string',
    required: false,
    renderCell: (params: any) => (
      <div
        className="hitCollections-buttons"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          variant="text"
          color="primary"
          onClick={async () => {
            await handleCreateRiskCase(params.row.id);
            getCollections();
          }}
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          Create case
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={async () => {
            await RiskDiscoveryApi.archiveCollections([params.row.id]);
            getCollections();
          }}
        >
          Archive
        </Button>
      </div>
    ),
  },
];

export default function RiskDiscoveryHitCollections() {
  const dispatch = useDispatch();
  const collections = useSelector((state: any) => state.hits.collections);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [articles, setArticles] = useState<Article[]>([]);

  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const loading = useSelector((state: any) => state.hits.collections.loading);

  const isKwmRoute = location.pathname.includes('/kwm/');
  const isSupplierRoute = location.pathname.includes('/supplier/');
  const isArchivedKeywordRoute =
    location.pathname.includes('/archived-keyword/');
  const isArchivedSupplierRoute = location.pathname.includes(
    '/archived-supplier/'
  );

  const handleViewClick = (articleId: number) => {
    let foundIndex = -1;
    let allArticles: Article[] = [];
    collections.data.forEach((collection: Collection) => {
      if (collection.articles) {
        allArticles = [...allArticles, ...collection.articles];
      }
    });

    foundIndex = allArticles.findIndex(
      (article: Article) => article.id === articleId
    );

    setArticles(allArticles);
    setCurrentIndex(foundIndex);
    setPopupVisible(true);
  };

  const handleCreateRiskCase = async (collectionId: number) => {
    const selectedCollection = collections.data.find((collection: any) => collection.id === collectionId);
    if (!selectedCollection) {
      dispatch(setSnackbar({
        open: true,
        severity: 'error',
        message: 'Collection not found.',
      }));
      return;
    }

    const selectedArticleIds = selectedCollection.articles.map((article: any) => article.id);
    dispatch(
      articleRiskCase({
        articles: selectedCollection.articles,
      })
    );
    try {
      const riskCaseTemplate = await RiskCaseApi.getRiskCaseTemplate(selectedArticleIds);
      const updatedMeasures = riskCaseTemplate.measures.riskMeasures.map((measure: any) => ({
        ...measure,
        selectedLevelId: measure.isRequired ? 1 : measure.selectedLevelId
      }));

      const body: RiskCaseCollection = {
        generalInformation: {
          riskCaseId: 0,
          dateAdded: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
          dateDeleted: null,
          isActive: true,
          isDeleted: false,
          riskCaseName: `Risk Case for ${selectedCollection.collectionName}`,
          comments: riskCaseTemplate.generalInformation.comments,
          description: riskCaseTemplate.generalInformation.description,
          owner: riskCaseTemplate.generalInformation.owner
            ? {
                key: riskCaseTemplate.generalInformation.owner.key,
                name: riskCaseTemplate.generalInformation.owner.name || '',
              }
            : null,
          subscribers: riskCaseTemplate.generalInformation.subscribers,
          suppliers: riskCaseTemplate.generalInformation.suppliers,
          keywordModels: riskCaseTemplate.generalInformation.keywordModels,
        },
        measures: {
          ...riskCaseTemplate.measures,
          riskMeasures: updatedMeasures
        },
        caseLogs: {
          caseLogs: []
        }
      };

      const riskCase = await RiskDiscoveryApi.createCollectionRiskCase([collectionId], body);
      dispatch(
        setRiskCase({ 
          data: riskCase, 
          isPopupVisible: true, 
          articleIds: selectedArticleIds 
        })
      );

      dispatch(setSnackbar({
        open: true,
        severity: 'success',
        message: 'Risk case created successfully.',
      }));
    } catch (error) {
      console.error('Error creating risk case:', error);
      dispatch(setSnackbar({
        open: true,
        severity: 'error',
        message: 'Error creating risk case. Please try again.',
      }));
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
  };

  const handleRiskCaseClick = (articleId: number) => {
    let foundIndex = -1;
    let allArticles: Article[] = [];
    collections.data.forEach((collection: Collection) => {
      if (collection.articles) {
        allArticles = [...allArticles, ...collection.articles];
      }
    });

    foundIndex = allArticles.findIndex(
      (article: Article) => article.id === articleId
    );

    setArticles(allArticles);
    setCurrentIndex(foundIndex);
    setPopupRiskCasesVisible(true);
  };

  const handleRiskCasesClose = () => {
    setPopupRiskCasesVisible(false);
  };


  const archivedSupplierEntry = useSelector((state: any) => {
    if (isArchivedSupplierRoute) {
      return (
        Object.entries(
          state.discovery.keywordModel.archivedHits.bySupplier
        ) as [string, ArchivedSupplierModel][]
      ).find(([key, archivedModel]) => archivedModel.id === Number(id));
    }
    return undefined;
  });
  const keywordModelEntry = useSelector((state: any) => {
    if (isKwmRoute) {
      return (
        Object.entries(state.discovery.keywordModel.byKeywordModel) as [
          string,
          KeywordModel
        ][]
      ).find(([key, model]) => model.id === Number(id));
    }
    return undefined;
  });
  const archivedKeywordModelEntry = useSelector((state: any) => {
    if (isArchivedKeywordRoute) {
      return (
        Object.entries(
          state.discovery.keywordModel.archivedHits.byKeywordModel
        ) as [string, ArchivedKeywordModel][]
      ).find(([key, archivedModel]) => archivedModel.id === Number(id));
    }
    return undefined;
  });

  const supplierEntry = useSelector((state: any) => {
    if (isSupplierRoute) {
      return (
        Object.entries(state.discovery.keywordModel.bySupplier) as [
          string,
          Supplier
        ][]
      ).find(([key, supplier]) => supplier.id === Number(id));
    }
    return undefined;
  });

  const idForSelectBox = isKwmRoute
    ? keywordModelEntry?.[1]?.id
    : isSupplierRoute
    ? supplierEntry?.[1]?.id
    : isArchivedKeywordRoute
    ? archivedKeywordModelEntry?.[1]?.id
    : archivedSupplierEntry?.[1]?.id || 0;

  const fetchCollections = async () => {
    try {
      dispatch(setCollectionsLoading(true));
      let response;
      if (isKwmRoute) {
        response = await ArticlesAPI.getCollectionsByKWM(Number(id), false);
      } else if (isSupplierRoute) {
        response = await ArticlesAPI.getCollectionsBySupplier(
          Number(id),
          false
        );
      } else if (isArchivedKeywordRoute) {
        response = await ArticlesAPI.getCollectionsByKWM(Number(id), true);
      } else if (isArchivedSupplierRoute) {
        response = await ArticlesAPI.getCollectionsBySupplier(Number(id), true);
      }
      if (response) {
        dispatch(setCollections(response));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setCollectionsLoading(false));
    }
  };

  const columns = createColumns(handleViewClick,handleCreateRiskCase, fetchCollections );

  const ExpandableRow = ({ data }: { data: any }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const articleColumns = createRiskDiscoveryColumns(
      handleRiskCaseClick,
      handleViewClick,
      fetchCollections
    );

    return (
      <div>
        <DataGridSupplierSetup
          dataSource={data.articles}
          columns={articleColumns}
          loading={loading}
        />
      </div>
    );
  };
  
  useEffect(() => {
    fetchCollections();
  }, [id]);

  const handleAction = async (
    selectedAction: { actionId?: ActionId },
    selectedRelatedAction: any
  ) => {
    const ids = extractIds(selectedRows);

    switch (selectedAction.actionId) {
      case ActionId.AddToRiskCase:
        await RiskDiscoveryApi.addCollectionsToRiskCase(
          selectedRelatedAction.id,
          ids
        );
        dispatch(
          setSnackbar({
            open: true,
            severity: 'success',
            message: `Collection${
              ids.length > 1 ? 's' : ''
            } successfully added to the risk case.`,
          })
        );
        break;
      case ActionId.CreateRiskCase:

      const selectedCollections = collections.data.filter((collection: any) => 
        ids.includes(collection.id)
      );
      const selectedArticles = selectedCollections.flatMap((collection: any) => 
        collection.articles || []
      );

      if (selectedArticles.length > 0) {
        dispatch(
          articleRiskCase({
            articles: selectedArticles,
          })
        );
      }
        const riskCaseTemplate = await RiskCaseApi.getRiskCaseTemplate(
          [139,140]
        );

        const updatedMeasures = riskCaseTemplate.measures.riskMeasures.map((measure:any) => ({
          ...measure,
          selectedLevelId: measure.isRequired ? 1 : measure.selectedLevelId
        }));
        const body: RiskCaseCollection = {
          generalInformation: {
            riskCaseId: 0,
            dateAdded: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            dateDeleted: null,
            isActive: true,
            isDeleted: false,
            riskCaseName: selectedRelatedAction,
            comments: riskCaseTemplate.generalInformation.comments,
            description: riskCaseTemplate.generalInformation.description,
            owner: riskCaseTemplate.generalInformation.owner
              ? {
                  key: riskCaseTemplate.generalInformation.owner.key,
                  name: riskCaseTemplate.generalInformation.owner.name || '',
                }
              : null,
            subscribers: riskCaseTemplate.generalInformation.subscribers,
            suppliers: riskCaseTemplate.generalInformation.suppliers,
            keywordModels: riskCaseTemplate.generalInformation.keywordModels,
          },
          measures: {
            ...riskCaseTemplate.measures,
            riskMeasures: updatedMeasures
          },
          caseLogs: {
            caseLogs: []
          }
        };

        const riskCase = await RiskDiscoveryApi.createCollectionRiskCase(
          ids,
          body
        );
        
        const selectedArticleIds = selectedArticles.map((article:any) => article.id);
        
        dispatch(
          setRiskCase({ data: riskCase, isPopupVisible: true, articleIds: selectedArticleIds }), 
        );

        break;
      case ActionId.Archive:
        await RiskDiscoveryApi.archiveCollections(ids);
        break;
    }

    fetchCollections();
  };

  const discoveryType = isKwmRoute
    ? DiscoveryType.ByKWM
    : DiscoveryType.BySupplier;

  return (
    <Card>
      <h4>Hit Collections</h4>

      <RiskDiscoverySelectBox
        mode="hitCollections"
        disabled={selectedRows.length === 0}
        kwmOrSupplierId={idForSelectBox}
        discoveryType={discoveryType}
        key={selectedRows.length}
        handleAction={handleAction}
      />

      <DataGridSupplierSetup
        dataSource={collections.data}
        columns={columns}
        loading={loading}
        setSelectedRowsData={setSelectedRows}
        ExpandableRowComponent={ExpandableRow}
      />

      {popupVisible && articles[currentIndex] && (
        <PopupArticle
          articles={articles}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          onClose={handlePopupClose}
        />
      )}

      <PopupRiskCases
        popupRiskCasesVisible={popupRiskCasesVisible}
        handleRiskCasesClose={handleRiskCasesClose}
        articles={articles}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </Card>
  );
}
