import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import compareStatusDates from '../../../../../utils/compare-status-date';

const theme = createTheme({
  palette: {
    alert: {
      main: '#FBC02D',
    },
  },
});

const getColorFromStatus = (status) => {
  switch (status) {
    case 'green':
      return theme.palette.success.main;
    case 'yellow':
      return theme.palette.alert.main;
    case 'red':
      return theme.palette.error.main;
    default:
      return theme.palette.success.main;
  }
};

const StyledChip = styled(Chip)(({ status }) => {
  const backgroundColor = getColorFromStatus(status, theme);
  return {
    backgroundColor,
    color: theme.palette.getContrastText(backgroundColor),
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
    height: '40px',
    textAlign: 'center',
    '&:hover': {
      opacity: 0.8,
    },
  };
});

const StatusIndicator = (data) => {
  const [sectionStatus, setSectionStatus] = useState([]);
  console.log('datos', data);
  const statusOrDefault = (dataArray) => {
    if (!Array.isArray(dataArray)) {
      return [];
    }
    return dataArray.map((item) => item.status || 'green');
  };

  const bankruptcyDataStatus = statusOrDefault(data.bankruptcyData);
  const claimsDataStatus = statusOrDefault(data.claimsData);
  const exclusionsDataStatus = statusOrDefault(data.exclusionsData);
  const judgmentsDataStatus = statusOrDefault(data.judgmentsData);
  const insolvencyDataStatus = statusOrDefault(data.insolvencyData);
  const liensDataStatus = statusOrDefault(data.liensData);
  const liquidationsDataStatus = statusOrDefault(data.liquidationsData);
  const suitsDataStatus = statusOrDefault(data.suitsData);
  const otherItemsDataStatus = statusOrDefault(data.otherItemsData);
  useEffect(() => {
    const liens = compareStatusDates(liensDataStatus);
    const bankruptcy = compareStatusDates(bankruptcyDataStatus);
    const claims = compareStatusDates(claimsDataStatus);
    const exclusions = compareStatusDates(exclusionsDataStatus);
    const judgements = compareStatusDates(judgmentsDataStatus);
    const insolvency = compareStatusDates(insolvencyDataStatus);
    const liquidation = compareStatusDates(liquidationsDataStatus);
    const suits = compareStatusDates(suitsDataStatus);
    const otherItems = compareStatusDates(otherItemsDataStatus);

    const statusFilings = [
      {
        name: 'Suits',
        id: 'suits',
        status:
          suits.length === 0
            ? 'green'
            : suits?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
      {
        name: 'Other Items',
        id: 'otherItems',
        status:
          otherItems.length === 0
            ? 'green'
            : otherItems?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
      {
        name: 'Liens',
        id: 'liens',
        status:
          liens.length === 0
            ? 'green'
            : liens?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
      {
        name: 'Bankruptcy',
        id: 'bankruptcy',
        status:
          bankruptcy.length === 0
            ? 'green'
            : bankruptcy?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
      {
        name: 'Claims',
        id: 'claims',
        status:
          claims.length === 0
            ? 'green'
            : claims?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
      {
        name: 'Exclusions',
        id: 'exclusions',
        status:
          exclusions.length === 0
            ? 'green'
            : exclusions?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
      {
        name: 'Judgments',
        id: 'judgments',
        status:
          judgements.length === 0
            ? 'green'
            : judgements?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
      {
        name: 'Insolvency',
        id: 'insolvency',
        status:
          insolvency.length === 0
            ? 'green'
            : insolvency?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
      {
        name: 'Liquidation',
        id: 'liquidation',
        status:
          liquidation?.length === 0
            ? 'green'
            : liquidation?.every((item) => item.isMoreThanOneYear)
            ? 'yellow'
            : 'red',
      },
    ];

    setSectionStatus(statusFilings);
  }, []);

 

  const handleScrollToSection = (id) => {
    const sectionElement = document.getElementById(id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Box>
      <Typography>
        <p className="filing-datagrid-primary-title">Status</p>
      </Typography>
      <Grid container spacing={2}>
        {sectionStatus.map((section, index) => (
          <Grid item xs={12} sm={6} md={2} key={index}>
            <StyledChip
              label={section.name}
              status={section.status}
              clickable
              onClick={() => handleScrollToSection(section.id)}
              aria-label={`Go to ${section.name} section`}
              v
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StatusIndicator;
