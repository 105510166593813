import httpClient from "../configs/axiosConfig";
import config from "../../config";

const { SUPPLIERS_SERVICE_URL } = config;

const SupplierLocation = {
    getAllSupplierLocations: async function (supplierId) {
        const { data } = await httpClient.get(`${SUPPLIERS_SERVICE_URL}/SupplierLocation/GetAllForSupplier/${supplierId}`);
        return data.result;
    },

    addSupplierLocation: async function (addSupplierLocationBody) {
        const { data } = await httpClient.post(`${SUPPLIERS_SERVICE_URL}/SupplierLocation`, addSupplierLocationBody)
        return data;
    },

    deleteSupplierLocation: async function (id) {
        const { data } = await httpClient.delete(`${SUPPLIERS_SERVICE_URL}/SupplierLocation/${id}`);
        return data.result;
    },

    updateSupplierLocation: async function (updateSupplierNameBody) {
        const { data } = await httpClient.put(`${SUPPLIERS_SERVICE_URL}/SupplierLocation`, updateSupplierNameBody);
        return data.result;
    },
}

export default SupplierLocation;