import React, { useState } from 'react';
import Button from 'devextreme-react/button';
import logo from '../../utils/rheinmetall.png';
import './SubMenu.scss';
import SuppliersMenu from '../suppliers-menu/SuppliersMenu';
import RiskDiscoveryMenu from '../risk-Discovery-menu/RiskDiscoveryMenu';
import RiskModelsMenu from '../risk-Models/risk-models-nav/RiskModelsMenu';

import RiskCasesMenu from '../risk-cases-menu/RiskCasesMenu';
import AdminMenu from '../admin-menu/AdminMenu';

const SubMenu = ({ returnToRootMenu, text, setSelectedMenuOption }) => {
	const [subMenuInput, setSubMenuInput] = useState("");

	const filterSuppliers = (e) => {
		setSubMenuInput(e.target.value);
	};

	const clearInput = () => {
		setSubMenuInput("");
	};

  const drawMenuItems = () => {
    if (text === 'Suppliers') {
      return <SuppliersMenu subMenuInput={subMenuInput} />;
    } else if (text === 'Risk Models') {
      return <RiskModelsMenu/>;
    } else if (text === 'Discovery') {
      return <RiskDiscoveryMenu />;
    } else if (text === 'Risk Cases') {
		return <RiskCasesMenu />;
	} else if (text === "NSN Query") {
		return <div>NSN Query</div>;
    } else if (text === 'Admin') {
      return <AdminMenu />
    }
  };

	return (
		<div className="root-menu-container">
			<div className="root-menu-top-wrapper">
				<div className="submenu-return-container">
					<Button
						text={text}
						onClick={returnToRootMenu}
						icon="back"
						stylingMode="text"
						className="submenu-return-button"
					/>
				</div>
				{text === "Suppliers" && (
					<div className="submenu-input-container">
						<input
							value={subMenuInput}
							onChange={filterSuppliers}
							placeholder={"Search"}
							className="submenu-input-search"
						/>
					</div>
				)}
				{drawMenuItems()}
			</div>
			<img className="root-menu-logo" src={logo} alt="Rheinmetall-Logo" />
		</div>
	);
};

export default SubMenu;
