import { createSlice } from "@reduxjs/toolkit";

const suppliersSlice = createSlice({
 name:'suppliers',
  initialState: {
    allSuppliers: [],
    selectedSupplier: {
      id: '',
      supplierName: '',
    },
    showOnlyActive: false,
  },
  reducers: {
    addSuppliers: ( state, action ) => {
      return {...state, allSuppliers: [...action.payload.allSuppliers]}
    },
    addSelectedSupplier: (state, action) => {
      return {...state, selectedSupplier: action.payload.selectedSupplier}
    },
    toggleShowOnlyActiveSwitch: (state) => {
      return {...state, showOnlyActive: !state.showOnlyActive}
    },
  },
});

export const { addSuppliers, addSelectedSupplier, toggleShowOnlyActiveSwitch } = suppliersSlice.actions;
export default suppliersSlice.reducer;
