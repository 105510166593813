import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import SuppliersAPI from "../../../api/suppliers/suppliersAPI";
import "./index.scss";

const RunModelsTab = () => {
  const { id } = useParams();
  const isNumber = !isNaN(parseInt(id));

  const [open, setOpen] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: '',
    severity: 'success',
    vertical: 'top',
    horizontal: 'right',
  });

  const handleButton = () => {
    if (isNumber) {
      setOpen(true);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const handleConfirm = async () => {
    setOpen(false);
    try {
      await SuppliersAPI.runPerigonFlowBySupplier(id);
      setSnackbarConfig({
        ...snackbarConfig,
        open: true,
        message: "The models are now running in the background. An email notification will be sent upon completion.",
        severity: 'success',
      });
    } catch (error) {
      setSnackbarConfig({
        ...snackbarConfig,
        open: true,
        message: error.response?.data?.message || "An error occurred",
        severity: 'error',
      });
    }
  };

  return (
    <div className="tab-container">
      <Button
        variant="contained"
        color="primary"
        onClick={handleButton}
        sx={{ width: '150px' }}
      >
        Run All Models
      </Button>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to run all models for this Supplier?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={handleConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: snackbarConfig.vertical, horizontal: snackbarConfig.horizontal }}
        open={snackbarConfig.open}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity} sx={{ width: '100%' }}>
          {snackbarConfig.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RunModelsTab;