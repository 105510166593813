
import React, { useCallback, useMemo } from 'react';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
  Switch,
  FormControlLabel,
  Autocomplete,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IRiskCase } from '../../../../components/risk-discovery/types/RiskCase';
import SelectBoxOption from '../../../../types/SelectBoxOption';

interface Option {
  id?: number;
  label?: string;
  name?: string;
  key?: string;
}

interface GeneralInformationProps {
  formData: IRiskCase;
  setFormData: React.Dispatch<React.SetStateAction<IRiskCase>>;
  owners?: SelectBoxOption[];
  subscribers?: SelectBoxOption[];
  suppliers?: SelectBoxOption[];
  kwm?: SelectBoxOption[];
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({
  formData,
  setFormData,
  owners = [],
  subscribers = [],
  suppliers = [],
  kwm = [],
}) => {
  const handleFieldChange = useCallback(
    (field: keyof IRiskCase, value: any) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: value,
      }));
    },
    [setFormData]
  );

  const handleMultipleChange = useCallback(
    (field: keyof IRiskCase, value: Option[] | number[]) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: value || [],
      }));
    },
    [setFormData]
  );

  const supplierOptions = useMemo(
    () =>
      suppliers.map((s) => ({
        id: s.id,
        label: s.label || '',
      })),
    [suppliers]
  );

  const supplierValue = useMemo(() => {
    const currentSuppliers = formData.suppliers || [];
    return Array.isArray(currentSuppliers)
      ? currentSuppliers.map((s) => {
          if (typeof s === 'object' && s !== null) {
            return s;
          }
          const foundSupplier = supplierOptions.find((opt) => opt.id === s);
          return foundSupplier || { id: s, label: '' };
        })
      : [];
  }, [formData.suppliers, supplierOptions]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Risk Case Name"
            value={formData.riskCaseName || ''}
            onChange={(e) => handleFieldChange('riskCaseName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            options={supplierOptions || []}
            getOptionLabel={(option) => option?.label || ''}
            value={supplierValue || []}
            onChange={(_, newValue) =>
              handleMultipleChange('suppliers', newValue || [])
            }
            renderInput={(params) => (
              <TextField {...params} label="Suppliers" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip {...getTagProps({ index })} label={option?.label || ''} />
              ))
            }
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Select Owner</InputLabel>
            <Select
              value={formData.owner?.key || ''}
              label="Select Owner"
              onChange={(e) => {
                const selectedOwner = owners.find(
                  (owner) => owner.key === e.target.value
                );
                handleFieldChange('owner', selectedOwner);
              }}
            >
              {owners.map((owner) => (
                <MenuItem key={owner.key} value={owner.key}>
                  {owner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            options={kwm}
            getOptionLabel={(option) => option.label || ''}
            value={formData.keywordModels || []}
            onChange={(_, newValue) =>
              handleMultipleChange('keywordModels', newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label="Keyword Model" />
            )}
            renderTags={(value: Option[], getTagProps) =>
              value.map((option: Option, index: number) => (
                <Chip label={option.label} {...getTagProps({ index })} />
              ))
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Date Created"
            value={formData.dateAdded ? new Date(formData.dateAdded) : null}
            onChange={(newValue) => handleFieldChange('dateAdded', newValue)}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.isActive}
                onChange={(e) =>
                  handleFieldChange('isActive', e.target.checked)
                }
              />
            }
            label="Is Active?"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            value={formData.description || ''}
            onChange={(e) => handleFieldChange('description', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={(subscribers as Option[]) || []}
            getOptionLabel={(option: Option) => option?.name || ''}
            value={(formData.subscribers as Option[]) || []}
            onChange={(_, newValue) =>
              handleMultipleChange('subscribers', newValue as Option[])
            }
            renderInput={(params) => (
              <TextField {...params} label="Subscribers" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.name} {...getTagProps({ index })} />
              ))
            }
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default GeneralInformation;
