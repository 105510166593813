import React from "react";
import "./HelpIcon.scss";
import informationPng from "../../../assets/information.png";
import HelpModal from "../../modal/HelpModal";

export default function HelpIcon({ label, children }) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const labelText = label + " Help";

	return (
		<div className="help-icon-container">
			<img
				onClick={handleClickOpen}
				title={labelText}
				src={informationPng}
				className="help-icon"
				alt={"Help Icon for" + label}
			/>
			<HelpModal open={open} onClose={handleClose} title={labelText}>
				{children}
			</HelpModal>
		</div>
	);
}
