import httpClient from "../configs/axiosConfig";
import config from "../../config";

const { SUPPLIERS_SERVICE_URL } = config;

const SupplierRelationType = {
  getAllSupplierRelations: async function () {
    const { data } = await httpClient.get(
      `${SUPPLIERS_SERVICE_URL}/SupplierRelationType/GetAll`
    );
    return data.result;
  },
};

export default SupplierRelationType;
