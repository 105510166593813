


import { IDataGridColumn } from "../components/data-grid/DataGridSupplierSetup";

export const createColumn = (
  field: string,
  headerName: string,
  type: 'string' | 'number' | 'boolean' | 'date' = 'string',
  required: boolean = false,
  showLink: boolean = false
): IDataGridColumn => ({
  field,
  headerName,
  type,
  required,
  showLink,
});

export const createPopupField = (key: string, label: string) => ({
  key,
  label,
});

export const extractIds = (items: any[]): number[] =>
  items.map((item) => item.id);
