import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createColumn } from "../../../utils/data-grid";
import { NsnDataGridHelpMenu } from "./NsnDataGridHelpMenu";
import "./NsnDetails.scss";

export default function NsnDetailStandardizationDataGrid({ data }) {
	// Create column definitions
	const columns = [
		{ ...createColumn("ISC", "ISC"), minWidth: 80, flex: 1 },
		{ ...createColumn("CAGECD", "CAGE CD"), minWidth: 100, flex: 1 },
		{
			...createColumn("DT_DECISION", "DT DECISION"),
			minWidth: 80,
			flex: 1,
		},
		{
			...createColumn("NIIN_STATCD", "NIIN STAT CD"),
			minWidth: 80,
			flex: 1,
		},
		{ ...createColumn("RPL_NSN", "RPL NSN"), minWidth: 80, flex: 1 },
		{ ...createColumn("RPL_ISC", "RPL ISC"), minWidth: 80, flex: 1 },
		{ ...createColumn("RPL_ORIG", "RPL ORIG"), minWidth: 80, flex: 1 },
		{
			...createColumn("RPL_DT_DECISION", "RPL DT DECISION"),
			minWidth: 80,
			flex: 1,
		},
		{
			...createColumn("RPL_NIIN_STATCD", "RPL NIIN STAT CD"),
			minWidth: 80,
			flex: 1,
		},
	];
	return (
		<Box className="nsn-detail-grid">
			<h5>Item Standardization Data</h5>
			<DataGrid
				autoHeight
				getRowId={() => 10000 * Math.random()}
				density="compact"
				rows={data}
				columns={columns}
				slots={{ columnMenu: NsnDataGridHelpMenu }}
			/>
		</Box>
	);
}
