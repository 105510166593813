import React from 'react'
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Sorting,
} from 'devextreme-react/data-grid';
import { formatCurrency, formatDate } from '../../../../../utils/format-helpers';

const ParticipantsGrid = ({ data }) => (
  <DataGrid dataSource={data} showBorders={true} columnAutoWidth={true}>
    <Column dataField="participantType" caption="Type" />
    <Column dataField="name" caption="Name" />
    <Column dataField="dunsNumber" caption="DUNS" />
    <Column
      dataField="value"
      caption="Filing Amount"
      cellRender={(cellData) => formatCurrency(cellData.data.value)}
    />
  </DataGrid>
);

const ReasonsGrid = ({ data }) => (
  <DataGrid dataSource={data} showBorders={true} columnAutoWidth={true}>
    <Column dataField="description" caption="Description" />
    <Column
      dataField="value"
      caption="Value"
      cellRender={(cellData) => formatCurrency(cellData.data.value)}
    />
  </DataGrid>
);

const ResultsGrid = ({ data }) => (
  <DataGrid dataSource={data} showBorders={true} columnAutoWidth={true}>
    <Column
      dataField="resultDate"
      caption="Result Date"
      cellRender={(cellData) => formatDate(cellData.data.resultDate)}
    />
    <Column dataField="description" caption="Description" />
  </DataGrid>
);

const DetailTemplate = ({ data }) => (
  <div>
    <p className="filing-datagrid-secondary-title">PARTICIPANTS</p>
    <ParticipantsGrid data={data.data.dnBInsolvencyParticipants} />
    <p className="filing-datagrid-secondary-title">FILING REASONS</p>
    <ReasonsGrid data={data.data.dnBInsolvencyFilingReasons} />
    <p className="filing-datagrid-secondary-title">FILING RESULTS</p>
    <ResultsGrid data={data.data.dnBInsolvencyFilingResults} />
  </div>
);

const InsolvencyComponent = ({ insolvencyData }) => {

  return (
    <>
      <p className="filing-datagrid-primary-title">Insolvency</p>
      <DataGrid
        dataSource={insolvencyData}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnReordering={true}
      >
        <Paging enabled={false} />
        <Sorting mode="multiple" />
        <Column
          dataField="dateFiled"
          caption="Filed"
          cellRender={(cellData) => formatDate(cellData.data.dateFiled)}
        />
        <Column dataField="insolvencyType" caption="Type" />
        <Column dataField="insolvencyClass" caption="Class" />
        <Column dataField="reference" caption="Reference" />
        <Column
          dataField="filingValue"
          caption="Filing Value"
          cellRender={(cellData) => formatCurrency(cellData.data.filingValue)}
        />
        <Column
          dataField="endDate"
          caption="End Date"
          cellRender={(cellData) => formatDate(cellData.data.endDate)}
        />
        <Column dataField="status" caption="Status" />
        <MasterDetail enabled={true} component={DetailTemplate} />
      </DataGrid>
    </>
  )
}

export default InsolvencyComponent;