// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";
import { ResponseDto, CageDetailDto } from "../types/types";

const { NSN_SERVICE_URL } = config;

const Help = {
	/**
	 * Gets information for a particular cage code.
	 * @param cage
	 * @returns
	 */
	getCageDetails: async function (
		cage: string
	): Promise<ResponseDto<CageDetailDto>> {
		const url = `${NSN_SERVICE_URL}/Help/Cage/${cage}`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<CageDetailDto>;
	},

	/**
	 * Gets information for a particular cage code.
	 * @param cage
	 * @returns
	 */
	getTermHelp: async function (term: string): Promise<ResponseDto<string>> {
		const url = `${NSN_SERVICE_URL}/Help/Term/${term}`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<string>;
	},
};

export default Help;
