// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";

const { ALTRUITY_API_URL } = config;

const Subscribers = {
  getAllSubscribersByKeywordModel: async function (keywordModelId: number) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/KeywordModels/GetAllSubscribersByKeywordModel/${keywordModelId}`
    );
    return data.result;
  },
};

export default Subscribers;
