import { configureStore } from '@reduxjs/toolkit';
import suppliersSlice from '../slices/suppliersSlice';
import authSlice from '../slices/authSlice';
import discoverySlice from '../slices/discoverySlice';
import hitsSlice from '../slices/hitsSlice';
import riskCasesSlice from '../slices/riskCasesSlice'
import permissionsSlice from '../slices/permissionsSlice'

import keywordModelSlice from '../slices/keywordModelSlice';

const store = configureStore ({
  reducer: {
    suppliers: suppliersSlice,
    auth: authSlice,
    discovery: discoverySlice,
    hits: hitsSlice,
    riskCases: riskCasesSlice,
    permission: permissionsSlice,
    keywordModel :keywordModelSlice,
  },
});

export default store;