import httpClient from "../configs/axiosConfig";
import config from "../../config";

const { SUPPLIERS_SERVICE_URL } = config;

const SupplierAlias = {
    getAllSupplierNames: async function (supplierId) {
        const { data } = await httpClient.get(`${SUPPLIERS_SERVICE_URL}/SupplierAlias/GetAllForSupplier/${supplierId}`);
        return data.result;
    },

    addSupplierName: async function (addSupplierNameBody) {
        const { data } = await httpClient.post(`${SUPPLIERS_SERVICE_URL}/SupplierAlias`, addSupplierNameBody)
        return data;
    },

    deleteSupplierName: async function (id) {
        const { data } = await httpClient.delete(`${SUPPLIERS_SERVICE_URL}/SupplierAlias/${id}`);
        return data.result;
    },

    updateSupplierName: async function (updateSupplierNameBody) {
        const { data } = await httpClient.put(`${SUPPLIERS_SERVICE_URL}/SupplierAlias`, updateSupplierNameBody);
        return data.result;
    },
}

export default SupplierAlias;