import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomMenuItem from "../custom-menu-item/CustomMenuItem";
import {
  Accordion,
  AccordionDetails,
  Button,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import groupMenuOptions from '../../utils/group-menu-options';
import './SuppliersMenu.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedSupplier } from '../../slices/suppliersSlice';
import SuppliersFilter from "./SuppliersFilter";

const SuppliersMenu = ({ subMenuInput }) => {
	const [filteredMenuOptions, setFilteredMenuOptions] = useState([]);
	const [accordionData, setAccordionData] = useState([
		{ ID: "1", characterGroup: "A - D", items: [] },
		{ ID: "2", characterGroup: "E - H", items: [] },
		{ ID: "3", characterGroup: "I - L", items: [] },
		{ ID: "4", characterGroup: "M - R", items: [] },
		{ ID: "5", characterGroup: "S - Z", items: [] },
	]);
	const [expanded, setExpanded] = useState(false);

	const dispatch = useDispatch();
	const suppliers = useSelector((state) => state.suppliers.allSuppliers);
	const selectedSupplier = useSelector(
		(state) => state.suppliers.selectedSupplier
	);
	const navigate = useNavigate();

	const handleChange = (panel) => {
		setExpanded(expanded === panel ? false : panel);
	};

	const getAccordionIndex = (supplierName) => {
		if (supplierName.trim().length === 0) {
			return -1;
		}
		const firstLetter = supplierName[0].toUpperCase();
		if (firstLetter >= "A" && firstLetter <= "D") return "panel1";
		if (firstLetter >= "E" && firstLetter <= "H") return "panel2";
		if (firstLetter >= "I" && firstLetter <= "L") return "panel3";
		if (firstLetter >= "M" && firstLetter <= "R") return "panel4";
		if (firstLetter >= "S" && firstLetter <= "Z") return "panel5";
		return -1;
	};

	useEffect(() => {
		const groupedMenuOptions = groupMenuOptions(suppliers);
		setAccordionData(groupedMenuOptions);
	}, [suppliers]);

	useEffect(() => {
		if (subMenuInput.length) {
			const filteredSuppliers = suppliers
				.filter((supplier) =>
					supplier.supplierName
						.toLowerCase()
						.includes(subMenuInput.toLowerCase())
				)
				.map((supplier) => ({
					id: supplier.id,
					text: supplier.supplierName,
					href: `/scrm/suppliers/${supplier.id}`,
				}));
			setFilteredMenuOptions(filteredSuppliers);
		} else {
			setFilteredMenuOptions([]);
		}
	}, [subMenuInput]);

	useEffect(() => {
		setExpanded(getAccordionIndex(selectedSupplier.supplierName));
	}, [selectedSupplier]);

  const handleAddSupplier = () => {
    dispatch(
      addSelectedSupplier({
        selectedSupplier: {
          id: '',
          supplierName: '',
        },
      })
    );
  };
  

  return (
    <>
      <SuppliersFilter />
      <div className="suppliers-menu-add-button-container">
        <Button
          className="suppliers-menu-add-button"
          variant="contained"
          onClick={() => {
            handleAddSupplier();
            navigate('/scrm/suppliers/add-supplier');
          }}
        >
          Add Supplier
        </Button>
      </div>
      {filteredMenuOptions.length > 0 ? (
        <div className="sub-menu-item-container">
          {filteredMenuOptions.map((supplier) => (
            <div
              key={supplier.id}
              className={`sub-menu-item ${
                selectedSupplier.id === supplier.id ? 'selected' : ''
              }`}
              style={{ marginTop: '8px' }}
              onClick={() => {
                navigate(`/scrm/suppliers/${supplier.id}`);
              }}
            >
              <Typography>
                <span>{supplier.text}</span>
              </Typography>
            </div>
          ))}
        </div>
      ) : subMenuInput.length > 0 ? (
        <div className="no-results-message"></div>
      ) : (
        <div className="container-accordion">
          {accordionData.map((data, index) => (
            <div key={data.ID ?? index}>
              <Box
                onClick={() => handleChange(`panel${index + 1}`)}
                className="accordion-header"
              >
                <Typography className="accordion-text">
                  {data.characterGroup}
                </Typography>
                <Typography className="accordion-icon">
                  {expanded === `panel${index + 1}` ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </Typography>
              </Box>
              {expanded === `panel${index + 1}` && (
                <Accordion>
                  <AccordionDetails>
                    <div className="accordion-content-container">
                      {data.items.map((item) => (
                        <div
                          key={item.id}
                          className={`sub-menu-item ${
                            selectedSupplier.id === item.id ? 'selected' : ''
                          }`}
                          onClick={() => {
                            navigate(`/scrm/suppliers/${item.id}`);
                          }}
                        >
                          <Typography>
                            <span>{item.supplierName}</span>
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SuppliersMenu;
