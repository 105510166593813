import React, { useEffect, useState, useMemo, useCallback } from "react";
import logo from "../../utils/rheinmetall.png";
import { useLocation, useNavigate } from "react-router-dom";
import "./RootMenu.scss";
import { Button, Toolbar, Drawer, List, Menu } from "devextreme-react";
import CustomItemMenu from "../custom-menu-item/CustomMenuItem";

const navigation = [
	{ id: 1, text: "Products" },
	{ id: 2, text: "Sales" },
	{ id: 3, text: "Customers" },
	{ id: 4, text: "Employees" },
	{ id: 5, text: "Reports" },
];

function NavigationList() {
	return (
		<div className="list">
			<List
				dataSource={navigation}
				className="panel-list dx-theme-typography-background-color"
				height={200}
			/>
		</div>
	);
}

const products = [
	{
		id: "1",
		icon: "menu",
		items: [
			{
				id: "1_1",
				name: "User Admin",
				route: "/user-admin/users",
			},
			{
				id: "1_2",
				name: "SCRM",
				route: "/scrm/dashboard",
			},
		],
	},
];

const RootMenu = ({
	menuOptions,
	selectedMenuOption,
	setSelectedMenuOption,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const getTitle = () => {
		if (location.pathname.startsWith("/scrm")) {
			return "SCRM";
		} else if (location.pathname.startsWith("/user-admin")) {
			return "User Admin";
		}
	};

	return (
		<div className="root-menu-container">
			<div className="root-menu-top-wrapper">
				<div className="root-menu-header-wrapper">
					<div className="root-menu-title">
						<h5 className="root-menu-title-primary">Altruity</h5>
						<h6 className="root-menu-title-secondary">
							{getTitle()}
						</h6>
					</div>
					<div>
						<Menu
							dataSource={products}
							displayExpr="name"
							showFirstSubmenuMode={{
								name: "onClick",
								delay: { show: 300, hide: 300 },
							}}
							orientation={"horizontal"}
							hideSubmenuOnMouseLeave={false}
							onItemClick={(e) => {
								navigate(e.itemData.route);
							}}
							rtlEnabled={true}
						/>
					</div>
				</div>
				<div className="root-menu-item-container">
					{menuOptions.map((option) => (
						<CustomItemMenu
							key={option.id}
							selectedMenuOption={selectedMenuOption}
							setSelectedMenuOption={setSelectedMenuOption}
							option={option}
						/>
					))}
				</div>
			</div>
			<img className="root-menu-logo" src={logo} alt="Rheinmetall-Logo" />
		</div>
	);
};

export default RootMenu;
