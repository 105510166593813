import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createColumn } from "../../../utils/data-grid";
import { NsnDataGridHelpMenu } from "./NsnDataGridHelpMenu";
import "./NsnDetails.scss";

export default function NsnDetailIdDataGrid({ data }) {
	// Create column definitions
	const columns = [
		{ ...createColumn("FIIG", "FIIG"), width: 70 },
		{ ...createColumn("INC", "INC"), width: 80, flex: 0 },
		{ ...createColumn("CRIT_CD", "CRIT CD"), width: 80 },
		{ ...createColumn("II", "II"), width: 60 },
		{ ...createColumn("RPD_MRC", "RPD MRC"), width: 100, flex: 0 },
		{ ...createColumn("DMIL", "DMIL"), width: 50 },
		{ ...createColumn("DMIL_INT_CD", "DMIL INT CD"), width: 150 },
		{ ...createColumn("NIIN_ASGMT", "NIIN ASGMT"), flex: 1 },
		{ ...createColumn("PMIC", "PMIC"), width: 80 },
		{ ...createColumn("ESD_EMI", "ESD EMI"), width: 80 },
		{ ...createColumn("HMIC", "HMIC"), width: 80 },
		{ ...createColumn("HCC", "HCC"), width: 80 },
	];
	return (
		<Box className="nsn-detail-grid">
			<h5>Identification</h5>
			<DataGrid
				autoHeight
				getRowId={() => 10000 * Math.random()}
				density="compact"
				rows={data}
				columns={columns}
				slots={{ columnMenu: NsnDataGridHelpMenu }}
			/>
		</Box>
	);
}
