import { useState } from "react";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { GridColumnMenu } from "@mui/x-data-grid";
import TermHelpModal from "../../modal/TermHelpModal";
import HelpIcon from "@mui/icons-material/Help";

function CustomUserItem(props) {
	const { myCustomHandler, myCustomValue } = props;
	return (
		<MenuItem onClick={myCustomHandler}>
			<ListItemIcon>
				<HelpIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>{myCustomValue}</ListItemText>
		</MenuItem>
	);
}

export function NsnDataGridHelpMenu(props) {
	const [helpModalState, setHelpModalState] = useState({
		open: false,
		term: "",
	});

	function handleHelpModalClose() {
		setHelpModalState((oldState) => ({
			...oldState,
			open: false,
		}));
	}
	return (
		<>
			<GridColumnMenu
				{...props}
				slots={{
					// Add new item
					columnMenuUserItem: CustomUserItem,
				}}
				slotProps={{
					columnMenuUserItem: {
						// set `displayOrder` for new item
						displayOrder: 0,
						// pass additional props
						myCustomValue: "Help",
						myCustomHandler: (params) => {
							setHelpModalState((oldState) => ({
								...oldState,
								open: true,
								term: props.colDef.field,
							}));
						},
					},
				}}
			/>
			<TermHelpModal
				open={helpModalState.open}
				term={helpModalState.term}
				onClose={handleHelpModalClose}
			/>
		</>
	);
}
