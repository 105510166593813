import axios from "axios";
import store from "../../store/store.js";

const httpClient = axios.create();

httpClient.interceptors.request.use(
	async (config) => {
		const state = store.getState();
		const token = state.auth.accessToken;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default httpClient;
