import httpClient from "../configs/axiosConfig";
import config from "../../config";

const { SUPPLIERS_SERVICE_URL } = config;

const SupplierIndustry = {
  getAllSupplierIndustries: async function (supplierId) {
    const { data } = await httpClient.get(
      `${SUPPLIERS_SERVICE_URL}/SupplierIndustry/GetAllForSupplier/${supplierId}`
    );
    return data.result;
  },

  addSupplierIndustry: async function (addSupplierIndustryBody) {
    const { data } = await httpClient.post(
      `${SUPPLIERS_SERVICE_URL}/SupplierIndustry`,
      addSupplierIndustryBody
    );
    return data;
  },

  deleteSupplierIndustry: async function (id) {
    const { data } = await httpClient.delete(
      `${SUPPLIERS_SERVICE_URL}/SupplierIndustry/${id}`
    );
    return data.result;
  },

  updateSupplierIndustry: async function (updateSupplierIndustryBody) {
    const { data } = await httpClient.put(
      `${SUPPLIERS_SERVICE_URL}/SupplierIndustry`,
      updateSupplierIndustryBody
    );
    return data.result;
  },
};

export default SupplierIndustry;
