import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArticleDto } from '../api/types/types';

interface Article {
  id: number;
  title: string;
  pubDate?: string;
  sourceDomain: string;
  url: string;
  sentimentMetrics: string;
  semanticSummary: string;
  description: string;
  imageUrl: string;
  suppliers: string;
  positiveSentiment: number;
  negativeSentiment: number;
  supplierArticles?: SupplierArticle[];
  articleKeywords?: ArticleKeyword[];
  keywordsAdditionalKwm?: ArticleKeyword[];
  
}

interface ExtendedArticleDto extends ArticleDto {
  keywordsAdditionalKwm?: ArticleKeyword[]; 
}


interface SupplierArticle {
  id: string;
  name: string;
}

interface ArticleKeyword {
  id: string;
  name: string;
}

interface ArticleRiskCaseState {
  articleRiskCase: Article[];

}

interface Collection {
  id: number;
  collectionName: string;
  articleCount: number;
  avgSent: number;
  articles: Article[];
  dateAdded: string;
  dateDeleted: string | null;
  dateUpdated: string | null;
  isActive: boolean;
  isDeleted: boolean;
  kwmOrSupplierId: number | undefined;
  discoveryTypeName: string;
}

interface CurrentState {
  loading: boolean ;
  data: ExtendedArticleDto[];
}

interface CollectionsState {
  loading: boolean;
  data: Collection[];
}

interface HitsState {
  current: CurrentState;
  collections: CollectionsState;
  additionalKeywords: AdditionalKeywordsState;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  articleRiskCase: Article[];
}

interface AdditionalKeywordsState {
  [articleId: number]: ArticleKeyword[];
}

const initialState: HitsState = {
  current: {
    loading: false,
    data: [],
  },
  collections: {
    loading: false,
    data: [],
  },
  additionalKeywords: {},
  status: 'idle',
  error: null,
  articleRiskCase: [],
  
};

// Define the slice
const hitsSlice = createSlice({
  name: 'discovery',
  initialState,
  reducers: {
    setCurrent(state, action: PayloadAction<ArticleDto[]>) {
      state.current.data = action.payload;
      state.current.loading =false;
    
    },
    setCollections(state, action: PayloadAction<Collection[]>) {
      state.collections.data = action.payload;
      state.collections.loading =false;
    },
    addArticleToCollection(state, action: PayloadAction<{ collectionId: number, article: Article }>) {
      const { collectionId, article } = action.payload;
      const collection = state.collections.data.find(c => c.id === collectionId);
      if (collection) {
        collection.articles.push(article);
        collection.articleCount += 1;
        
      }
    },
    createCollection(state, action: PayloadAction<{ collection: Collection, articles: Article[] }>) {
      const { collection, articles } = action.payload;
      state.collections.data.push({ ...collection, articles, articleCount: articles.length });
    },
    removeArticleFromCurrent(state, action: PayloadAction<number>) {
      state.current.data = state.current.data.filter(article => article.id !== action.payload);
    },
    setAdditionalsKeywords(state, action: PayloadAction<{ articleId: number; keywords: string[]}>) {
      const existingKeywords: ArticleKeyword[] = state.additionalKeywords[action.payload.articleId] || [];
      const newKeywords: ArticleKeyword[] = action.payload.keywords.map(keyword => ({ id: keyword, name: keyword }));
      
      const combinedKeywords = [...existingKeywords, ...newKeywords].reduce<ArticleKeyword[]>((acc, keyword) => {
        if (!acc.find(kw => kw.name === keyword.name)) {
          acc.push(keyword);
        }
        return acc;
      }, []);

      state.additionalKeywords[action.payload.articleId] = combinedKeywords;
    },
    articleRiskCase(state, action: PayloadAction<{ articles: Article[] }>) {
      const { articles } = action.payload;
      state.articleRiskCase = articles;
    },

    setStatus(state, action: PayloadAction<'idle' | 'loading' | 'succeeded' | 'failed'>) {
      state.status = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setCurrentLoading(state, action: PayloadAction<boolean>) {
      state.current.loading = action.payload;
    },
    setCollectionsLoading(state, action: PayloadAction<boolean>) {
      state.collections.loading = action.payload;
    },
  },
});

// Export actions
export const {
  setCurrent,
  setCollections,
  setStatus,
  setError,
  setCurrentLoading,
  setCollectionsLoading,
  addArticleToCollection,
  createCollection,
  removeArticleFromCurrent,
  setAdditionalsKeywords,
  articleRiskCase

} = hitsSlice.actions;

// Export reducer
export default hitsSlice.reducer;
