import { Snackbar, SnackbarContent } from "@mui/material";
import { createPortal } from "react-dom";

export default function ValidationSnackbar({ open, message, onClose }) {
	return createPortal(
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={open}
			autoHideDuration={3000}
			onClose={onClose}
			//key={snackBarState.message}
		>
			<SnackbarContent
				style={{
					backgroundColor: "red",
					fontWeight: "bold",
				}}
				message={message}
			/>
		</Snackbar>,
		document.getElementById("app-snackbar")
	);
}
