import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import {
  addKeywordModel,
  addSelectedKeywordModel,
} from '../../../slices/keywordModelSlice';
import KeywordModelAPI from '../../../api/keywordModel/keywordModelApi';

interface Keyword {
  id: number | null;
  text: string;
}

interface Frequency {
  id: number;
  frequencyName: string;
}

interface Synonym {
  id: number;
  text: string;
}

interface SentimentRule {
  id: number;
  ruleName: string;
}
interface Owners {
  id: number;
  ruleName: string;
}

interface Bodykeywordmodel {
  modelName: string;
  keywords: string;
  ownerId: number;
  searchFrequencyId: number;
  sentimentRuleId: number;
  isActive: boolean;
}

interface KeywordModelData {
  id?: number;
  modelName: string;
  ownerId: number;
  isActive: boolean;
  searchFrequency: {
    frequencyName: string;
    id: number;
  };
  sentimentRule: SentimentRule;
  keywords: string;
  subscribers: {
    email: string;
  }[];
}

interface KeywordModelState {
  allKeywordModel: KeywordModelData[];
  selectedKeywordModel: KeywordModelData | null;
}

interface RootState {
  keywordModel: KeywordModelState;
}

const KeywordModelForm: React.FC = () => {
  const [modelName, setModelName] = useState<string>('');
  const [owners, setOwners] = useState<Owners[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<number | null>(null);
  const [active, setActive] = useState<string>('true');
  const [frequency, setFrequency] = useState<string>('');
  const [subscribers, setSubscribers] = useState<string[]>([]);
  const [frequencyOfSearch, setFrequencyOfSearch] = useState<Frequency[]>([]);
  const [newSubscriber, setNewSubscriber] = useState<string>('');
  const [sentimentRule, setSentimentRule] = useState<SentimentRule[]>([]);
  const [selectedSentiment, setSelectedSentiment] = useState<number>();
  const [newKeywordPhrase, setNewKeywordPhrase] = useState<string>('');
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [synonyms, setSynonyms] = useState<Synonym[]>([
    { id: 1, text: 'workers' },
  ]);
  const [editingKeywordId, setEditingKeywordId] = useState<number | null>(null);
  const [formData, setFormData] = useState<Bodykeywordmodel>({
    modelName: '',
    keywords: '',
    ownerId: 0,
    searchFrequencyId: 0,
    sentimentRuleId: 0,
    isActive: true,
  });
  const [refreshKey, setRefreshKey] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const keywordModel = useSelector(
    (state: RootState) => state.keywordModel.allKeywordModel || []
  );

  const { id } = useParams<{ id: string }>();

  const keywordData = keywordModel.find(
    (keywordModel) => keywordModel.id === Number(id)
  );
  const subscribersData = keywordData
    ? keywordData.subscribers.map((subscriber) => subscriber.email)
    : [];

  const fetchData = async () => {
    try {
      const sentimentRule = await KeywordModelAPI.getAllSentimentRule();
      const frequencySearch = await KeywordModelAPI.getAllSearchFrequency();
      setFrequencyOfSearch(frequencySearch);
      setSentimentRule(sentimentRule);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (keywordData) {
      setModelName(keywordData.modelName);
      setSelectedOwner(keywordData.ownerId);
      setActive(keywordData.isActive ? 'true' : 'false');
      setFrequency(keywordData.searchFrequency.frequencyName);
      setSelectedSentiment(keywordData.sentimentRule.id);
      setKeywords(
        keywordData.keywords
          .split('|')
          .map((text, index) => ({ id: index + 1, text: text.trim() }))
      );
      setSubscribers(subscribersData);
      setFormData({
        modelName: keywordData.modelName,
        keywords: keywordData.keywords,
        ownerId: keywordData.ownerId,
        searchFrequencyId: keywordData.searchFrequency?.id || 0,
        sentimentRuleId: keywordData.sentimentRule.id,
        isActive: keywordData.isActive ? true : false,
      });
    }
  }, []);

  const handleDeleteSubscriber = (email: string) => {
    setSubscribers((prevSubscribers) =>
      prevSubscribers.filter((sub) => sub !== email)
    );
  };

  const handleAddSubscriber = () => {
    if (
      newSubscriber.trim() !== '' &&
      !subscribers.includes(newSubscriber.trim())
    ) {
      setSubscribers([...subscribers, newSubscriber.trim()]);
      setNewSubscriber('');
    }
  };
  const handleAddKeywordPhrase = () => {
    if (newKeywordPhrase.trim() !== '') {
      const newKeyword = {
        id: keywords.length + 1,
        text: newKeywordPhrase.trim(),
      };
      setKeywords([...keywords, newKeyword]);
      setNewKeywordPhrase('');
      setFormData((prev) => ({
        ...prev,
        keywords: [...keywords.map((k) => k.text), newKeywordPhrase].join('|'),
      }));
    }
  };

  const handleDeleteKeywordPhrase = (text: string) => () => {
    setKeywords((prevKeywords) => {
      const newKeywords = prevKeywords.filter(
        (keyword) => keyword.text !== text
      );
      setFormData((prev) => ({
        ...prev,
        keywords: newKeywords.map((k) => k.text).join('|'),
      }));
      return newKeywords;
    });
  };

  const handleEditKeywordPhrase = (keyword: Keyword) => () => {
    setEditingKeywordId(keyword.id);
    setNewKeywordPhrase(keyword.text);
  };
  const handleSaveKeywordEdit = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && editingKeywordId !== null) {
      setKeywords((prevKeywords) => {
        const newKeywords = prevKeywords.map((keyword) =>
          keyword.id === editingKeywordId
            ? { ...keyword, text: newKeywordPhrase }
            : keyword
        );
        setFormData((prev) => ({
          ...prev,
          keywords: newKeywords.map((k) => k.text).join('|'),
        }));
        return newKeywords;
      });
      setEditingKeywordId(null);
      setNewKeywordPhrase('');
    }
  };

  const handleAddSynonym = (synonym: Synonym) => {
    if (!synonyms.some((s) => s.text === synonym.text)) {
      setSynonyms([...synonyms, { ...synonym, id: synonyms.length + 1 }]);
    }
  };

  const handleAddAllSynonyms = () => {
    const newSynonyms = synonyms.map((synonym, index) => ({
      ...synonym,
      id: synonyms.length + 1,
    }));
    setSynonyms(newSynonyms);
  };

  const handleDeleteSynonym = (synonym: Synonym) => {
    setSynonyms(synonyms.filter((s) => s.text !== synonym.text));
  };

  const handleSave = async () => {
    try {
      const modelNameExists = keywordModel.some(
        (model) =>
          model.modelName.toLowerCase() === formData.modelName.toLowerCase()
      );

      if (modelNameExists) {
        alert(
          'A model with this name already exists. Please choose a different name.'
        );
        return;
      }

      const { result: newKeywordModel } =
        await KeywordModelAPI.createKeywordModel(formData);
      setFormData({
        modelName: '',
        keywords: '',
        ownerId: 0,
        searchFrequencyId: 0,
        sentimentRuleId: 0,
        isActive: true,
      });

      const data = await KeywordModelAPI.getAllKeywordModel();
      dispatch(addKeywordModel({ allKeywordModel: data }));

      dispatch(
        addSelectedKeywordModel({
          selectedKeywordModel: {
            id: newKeywordModel.id,
            modelName: newKeywordModel.modelName,
          },
        })
      );
      alert('Data successfully saved.');
      navigate(`/scrm/risk-models/${newKeywordModel.id}`);
      setRefreshKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error(error);
    }
  };

  const inputStyles = {
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
    height: '46px',
    borderColor: 'none',
    fontSize: '14px',
  };

  const labelStyles = {
    textTransform: 'uppercase',
    position: 'relative',
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className="new-keywordModels"
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Model Name
            </InputLabel>
            <TextField
              required
              fullWidth
              id="modelName"
              name="modelName"
              value={formData.modelName}
              onChange={(e) =>
                setFormData({ ...formData, modelName: e.target.value })
              }
              InputProps={{ style: inputStyles }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Owner
            </InputLabel>
            <Select
              value={formData.ownerId || ''}
              onChange={(e) =>
                setFormData({ ...formData, ownerId: Number(e.target.value) })
              }
              displayEmpty
              style={inputStyles}
            >
              <MenuItem value="">
                <em>Select...</em>
              </MenuItem>

              {/* {owners.map(owner => 
                <MenuItem key={owner.id} value={owner.name}>
                {owner.name}
                </MenuItem>
              )} */}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Active?
            </InputLabel>
            <Select
              id="isActive"
              name="isActive"
              value={formData.isActive}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  isActive: e.target.value === 'true',
                })
              }
              displayEmpty
              style={inputStyles}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Frequency of Search
            </InputLabel>
            <Select
              value={formData.searchFrequencyId}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  searchFrequencyId: Number(e.target.value),
                })
              }
              displayEmpty
              style={inputStyles}
            >
              <MenuItem value="">
                <em>Select...</em>
              </MenuItem>
              {frequencyOfSearch.map((frequency) => (
                <MenuItem key={frequency.id} value={frequency.id}>
                  {frequency.frequencyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Sentiment Rule
            </InputLabel>
            <Select
              value={formData.sentimentRuleId || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  sentimentRuleId: Number(e.target.value),
                })
              }
              displayEmpty
              style={inputStyles}
            >
              <MenuItem value="">
                <em>Select...</em>
              </MenuItem>
              {sentimentRule.map((sentiment) => (
                <MenuItem key={sentiment.id} value={sentiment.id}>
                  {sentiment.ruleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Subscribers
            </InputLabel>
            <Box
              sx={{
                ...inputStyles,
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            >
              {subscribers.map((email, index) => (
                <Chip
                  key={index}
                  label={email}
                  onDelete={() => handleDeleteSubscriber(email)}
                  color="primary"
                  sx={{
                    padding: '10px',
                    backgroundColor: '#E2E2E2',
                    color: '#1D1D1D',
                  }}
                />
              ))}
            </Box>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            value={newSubscriber}
            onChange={(e) => setNewSubscriber(e.target.value)}
            placeholder="Add subscriber"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleAddSubscriber();
              }
            }}
            InputProps={{ style: inputStyles }}
            style={{ margin: '20px' }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Add a Keyword / Phrase</Typography>
          <TextField
            value={newKeywordPhrase}
            onChange={(e) => setNewKeywordPhrase(e.target.value)}
            placeholder="Add Keyword / Phrase"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                if (editingKeywordId !== null) {
                  handleSaveKeywordEdit(e);
                } else {
                  handleAddKeywordPhrase();
                }
              }
            }}
            InputProps={{ style: inputStyles }}
            style={{ margin: '10px' }}
          />
          <Button onClick={handleAddKeywordPhrase}>Add</Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Keyword</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keywords?.map((keyword) => (
                  <TableRow key={keyword.id}>
                    <TableCell>{keyword.text}</TableCell>
                    <TableCell>
                      <Button onClick={handleEditKeywordPhrase(keyword)}>
                        Edit
                      </Button>
                      <Button onClick={handleDeleteKeywordPhrase(keyword.text)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6">Synonym</Typography>
          <Button onClick={handleAddAllSynonyms}>Add all</Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Synonym</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {synonyms?.map((synonym) => (
                  <TableRow key={synonym.id}>
                    <TableCell>{synonym.text}</TableCell>
                    <TableCell>
                      {synonyms.some((s) => s.text === synonym.text) ? (
                        <Button onClick={() => handleDeleteSynonym(synonym)}>
                          Delete
                        </Button>
                      ) : (
                        <Button onClick={() => handleAddSynonym(synonym)}>
                          Add
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ width: '100px' }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KeywordModelForm;
