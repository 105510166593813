import React from "react";
import SelectBox from "../../../components/select-box/SelectBox";
import "./RiskDiscoverySelectBox.scss";
import ActionId from "../consts/actions";
import RiskDiscoveryApi from "../../../api/riskDiscovery/riskDiscoverylAPI";
import DiscoveryType from "../consts/discoveryType";

interface IRiskDiscoverySelectBoxProps {
  mode: "hits" | "hitCollections";
  disabled?: boolean;
  kwmOrSupplierId?: number;
  handleAction: (selectedAction: any, selectedRelatedAction: any) => void;
  discoveryType: DiscoveryType;
}

const RiskDiscoverySelectBox = ({
  mode,
  disabled = false,
  kwmOrSupplierId,
  handleAction,
  discoveryType,
}: IRiskDiscoverySelectBoxProps) => {
  const selectBoxActions = [
    ...(mode === "hits"
      ? [
          {
            actionId: ActionId.AddToHitCollection,
            actionName: "Add to Hit Collection",
          },
          {
            actionId: ActionId.CreateHitCollection,
            actionName: "Create Hit Collection",
          },
        ]
      : []),
    { actionId: ActionId.AddToRiskCase, actionName: "Add to Risk Case" },
    { actionId: ActionId.CreateRiskCase, actionName: "Create Risk Case" },
    { actionId: ActionId.Archive, actionName: "Archive" },
  ];

  const placeholders: Record<ActionId, string> = {
    [ActionId.CreateHitCollection]: "Enter a Collection Name...",
    [ActionId.AddToHitCollection]: "Select Hit Collection...",
    [ActionId.CreateRiskCase]: "Enter a Risk Case Name...",
    [ActionId.AddToRiskCase]: "Select Risk Case...",
    [ActionId.Archive]: "",
  };

  const inputType: Record<ActionId, "selectBox" | "text" | ""> = {
    [ActionId.CreateHitCollection]: "text",
    [ActionId.AddToHitCollection]: "selectBox",
    [ActionId.CreateRiskCase]: "text",
    [ActionId.AddToRiskCase]: "selectBox",
    [ActionId.Archive]: "",
  };

  const relatedInputPlaceholder = (selectedAction: { actionId?: ActionId }) => {
    if (selectedAction?.actionId === undefined) return "";
    return placeholders[selectedAction.actionId];
  };

  const relatedInputType = (selectedAction: { actionId?: ActionId }) => {
    if (selectedAction?.actionId === undefined) return "";
    return inputType[selectedAction.actionId];
  };

  // const relatedInputDataSource = (selectedAction: { actionId?: ActionId }) => {
  //   if (selectedAction?.actionId === undefined) return [];
  //   return RiskDiscoveryApi.getSelectBoxOptions(
  //     ActionId[selectedAction.actionId],
  //     keywordModelId
  //   );
  // };
  const relatedInputDataSource = (selectedAction: { actionId?: ActionId }) => {
    if (selectedAction?.actionId === undefined) return [];
    const validKwmOrSupplierId = kwmOrSupplierId ?? 0;
    return RiskDiscoveryApi.getSelectBoxOptions(
      ActionId[selectedAction.actionId],
      validKwmOrSupplierId,
      DiscoveryType[discoveryType]
    );
  };

  return (
    <SelectBox
      dataSource={selectBoxActions}
      className="selectBoxPadding"
      label={`With selected items`}
      placeholder="Select an action..."
      displayExpr="actionName"
      actionName="Go"
      handleAction={handleAction}
      disabled={disabled}
      relatedInput={
        disabled
          ? undefined
          : {
              dataSource: (selectedAction) =>
                relatedInputDataSource(selectedAction),
              displayExpr: "label",
              type: (selectedAction) => relatedInputType(selectedAction),
              placeholder: (selectedAction) =>
                relatedInputPlaceholder(selectedAction),
            }
      }
    />
  );
};

export default RiskDiscoverySelectBox;
