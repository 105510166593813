import React, { useEffect, useState } from 'react';
import Tabs from 'devextreme-react/tabs';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RiskCaseOverview from '../../components/risk-Cases-tab/overview/index';
import './index.scss';
import RiskCaseApi from '../../api/riskCase/riskCaseApi';
import {
  setRiskCurrentCaseName,
  updateRiskCaseName,
} from '../../slices/riskCasesSlice';
import CaseLog from '../../components/risk-discovery/risk-case-popup/sections/CaseLog';
import SelectBoxOption from '../../types/SelectBoxOption';
import { format } from 'date-fns';
import { AlertColor, Box, Button } from '@mui/material';
import CustomSnackbar from '../../components/custom-snackbar';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface RiskCase {
  generalInformation: {
    riskCaseId: number;
    dateAdded: string;
    dateUpdated: string;
    dateDeleted: null;
    isActive: boolean;
    isDeleted: boolean;
    riskCaseName: string;
    comments: string;
    description: string;
    owner: Owner | null;
    subscribers: any[];
    suppliers: any[];
    keywordModels: any[];
  };
  measures: {
    riskMeasures: Measure[];
  };

  caseLogs: {
    caseLogs: any[];
  };
}

interface Measure {
  riskMeasureId: number;
  description: string;
  availableRiskMeasureLevels: RiskMeasureLevel[];
  riskMeasureName: string;
  isRequired?: boolean;
}

interface RiskMeasureLevel {
  id: number;
  label: string;
  comments: string;
  selectedLevelId: number;
  isRequired: boolean;
}

interface Owner {
  key: string;
  name: string;
}

interface TabItemData {
  id: number;
  text: string;
}

interface TabClickEvent {
  itemData?: TabItemData;
}

const RISKCASE_TABS = [
  { id: 0, text: 'Overview' },
  // { id: 1, text: 'Parts / Assemblies' },
  { id: 2, text: 'Case Log' },
  // { id: 3, text: 'Articles' },
];

const RiskCase: React.FC = () => {
  const [tabId, setTabId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [ownersDropdown, setOwnersDropdown] = useState<Owner[]>([]);
  const [formDataCaseLog, setFormDataCaseLog] = useState<any>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const [riskCaseData, setRiskCaseData] = useState<RiskCase>({
    generalInformation: {
      riskCaseId: 0,
      riskCaseName: '',
      dateAdded: new Date().toISOString(),
      dateUpdated: new Date().toISOString(),
      dateDeleted: null,
      isActive: true,
      isDeleted: false,
      comments: '',
      description: '',
      owner: null,
      subscribers: [],
      suppliers: [],
      keywordModels: [],
    },
    measures: {
      riskMeasures: [],
    },
    caseLogs: {
      caseLogs: [],
    },
  });
  const { id } = useParams<{ id: string }>();
  const numercId = Number(id);

  const dispatch = useDispatch();
  const currentRiskCaseName = useSelector(
    (state: any) => state.riskCases.currentRiskCaseName
  );

  const fetchRiskCase = async () => {
    try {
      const data = await RiskCaseApi.getRiskCaseById(numercId);
      if (currentRiskCaseName !== data.generalInformation.riskCaseName) {
        dispatch(setRiskCurrentCaseName(data.generalInformation.riskCaseName));
      }
      setFormDataCaseLog(data);
      setRiskCaseData(data);
      const ownersDropdown = await RiskCaseApi.getOwnersDropdown();
      setOwnersDropdown(ownersDropdown);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsReady(true);
    setLoading(false);
    fetchRiskCase();
  }, [id]);

  const handleSave = async () => {
    try {
      const updatedRiskCase = await RiskCaseApi.updateRiskCase(riskCaseData);
      if (
        currentRiskCaseName !== updatedRiskCase.generalInformation.riskCaseName
      ) {
        dispatch(
          setRiskCurrentCaseName(
            updatedRiskCase.generalInformation.riskCaseName
          )
        );
        dispatch(
          updateRiskCaseName({
            id: updatedRiskCase.generalInformation.riskCaseId,
            newName: updatedRiskCase.generalInformation.riskCaseName,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onTabClick = (e: TabClickEvent) => {
    if (e.itemData) {
      setTabId(e.itemData.id);
    }
  };

  const getTabs = () => {
    if (id) {
      return RISKCASE_TABS.map((tab) =>
        tab.id === 1 ? { ...tab, disabled: true } : tab
      );
    }
    return RISKCASE_TABS;
  };

  const handleArchiveConfirmation = () => {
    setOpenConfirmDialog(true);
  };

  const handleArchived = async () => {
    try {
      const archivedRiskCase = await RiskCaseApi.archiveRiskCase(numercId);
      setOpenConfirmDialog(false);
      setOpenConfirmDialog(false);
      setSnackbarMessage('Risk case archived successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);
      setSnackbarMessage('Error archiving risk case');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const updateGeneralInformation = (
    newGeneralInformation: Partial<typeof riskCaseData.generalInformation>
  ) => {
    setRiskCaseData((prevData) => ({
      ...prevData,
      generalInformation: {
        ...prevData.generalInformation,
        ...newGeneralInformation,
      },
    }));
  };

  const updateMeasures = (
    newMeasures: typeof riskCaseData.measures.riskMeasures
  ) => {
    setRiskCaseData((prevData) => ({
      ...prevData,
      measures: {
        ...prevData.measures,
        riskMeasures: newMeasures,
      },
    }));
  };

  const updateCaseLogs = (
    newCaseLogs: typeof riskCaseData.caseLogs.caseLogs
  ) => {
    const casesLogsWithoutDate = newCaseLogs.map(({ date, ...rest }) => rest);
    setRiskCaseData((prevData) => ({
      ...prevData,
      caseLogs: {
        ...prevData.caseLogs,
        caseLogs: casesLogsWithoutDate,
      },
    }));
  };

  const displayTab = () => {
    switch (tabId) {
      case 0:
        return (
          <RiskCaseOverview
            generalInformation={riskCaseData.generalInformation}
            measures={riskCaseData.measures.riskMeasures}
            updateGeneralInformation={updateGeneralInformation}
            updateMeasures={updateMeasures}
          />
        );
      case 2:
        return (
          <CaseLog
            owners={ownersDropdown}
            formData={formDataCaseLog}
            updateCaseLogs={updateCaseLogs}
          />
        );
      // case 3:
      // return <ArticleRiskCase />
      default:
        return null;
    }
  };

  return (
    <div className="risk-case-page-container" key={id}>
      <h4 className="risk-case-title">{currentRiskCaseName}</h4>
      <div className="tabs-demo">
        <div className="widget-container">
          {isReady && (
            <Tabs
              id="withText"
              width={'700px'}
              selectedIndex={getTabs().findIndex((tab) => tab.id === tabId)}
              // selectedIndex={tabId}
              rtlEnabled={false}
              dataSource={getTabs()}
              scrollByContent={false}
              showNavButtons={false}
              orientation={'horizontal'}
              stylingMode={'secondary'}
              iconPosition={'top'}
              onItemClick={onTabClick}
            />
          )}
        </div>
      </div>
      {displayTab()}
      <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleArchiveConfirmation}
        >
          Archive
        </Button>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
          {'Are you sure you want to archive this risk case?'}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleArchived} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RiskCase;
