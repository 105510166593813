// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";

const { ALTRUITY_API_URL } = config;

const SupplierArticle = {
  getSupplierIdsByArticleIds: async function (articleIds: number[]) {
    const articleIdsQuery = articleIds.join("&articleIds=");
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/Articles/GetSupplierIdsByArticleIds?articleIds=${articleIdsQuery}`
    );
    return data.result;
  },
};

export default SupplierArticle;
