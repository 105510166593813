export { default as AltruityTextField } from "./text-field/AltruityTextField";
export { default as AltruityCheckbox } from "./checkbox/AltruityCheckbox";
export { default as HelpIcon } from "./help/HelpIcon";
export { default as ValidationSnackbar } from "./validation/ValidationSnackbar";

export const labelStyles = {
	textTransform: "uppercase",
	position: "relative",
	transform: "scale(0.75)",
};

export const inputProps_textField = {
	style: {
		backgroundColor: "#F5F5F5",
		borderRadius: "10px",
		height: "46px",
		borderColor: "none",
		fontSize: "14px",
	},
};

export const textAreaStyles = {
	backgroundColor: "#F5F5F5",
	borderRadius: "10px",
	borderColor: "none",
	fontSize: "14px",
};
