import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createColumn } from "../../../utils/data-grid";
import { NsnDataGridHelpMenu } from "./NsnDataGridHelpMenu";
import "./NsnDetails.scss";

export default function NsnDetailFreightDataGrid({ data }) {
	// Create column definitions	// Create column definitions
	const columns = [
		{
			...createColumn("ORIG_ACTY_CD", "ORIG ACTY CD"),
			width: 120,
			flex: 0,
		},
		{ ...createColumn("NMFC", "NMFC"), width: 80, flex: 0 },
		{
			...createColumn("UFC_CD_MODF", "UFC CD MODF"),
			width: 120,
			flex: 0,
		},
		{
			...createColumn("HMC", "HMC"),
			width: 60,
			flex: 0,
		},
		{
			...createColumn("CLAS_RTNG_CD", "CLAS RTNG CD"),
			width: 120,
			flex: 0,
		},
		{
			...createColumn("LCL_CD", "LCL CD"),
			width: 70,
			flex: 0,
		},
		{
			...createColumn("RAIL_VARI_CD", "RAIL VARI CD"),
			minWidth: 80,
			flex: 1,
		},
		{
			...createColumn("WTR_CMDTY_CD", "WTR CMDTY CD"),
			minWidth: 120,
			flex: 1,
		},
		{
			...createColumn("TYPE_CGO_CD", "TYPE CGO CD"),
			minWidth: 100,
			flex: 1,
		},
		{
			...createColumn("SP_HDLG_CD", "SP HDLG CD"),
			minWidth: 100,
			flex: 1,
		},
		{
			...createColumn("AIR_DIM_CD", "AIR_DIM_CD"),
			minWidth: 100,
			flex: 1,
		},
		{
			...createColumn("AIR_CMTY_HDLG", "AIR CMTY HDLG"),
			minWidth: 120,
			flex: 1,
		},
		{
			...createColumn("INTGTY_CD", "INTGTY CD"),
			minWidth: 100,
			flex: 1,
		},
		{
			...createColumn("FRT_DESC", "FRT DESC"),
			minWidth: 100,
			flex: 1,
		},
	];

	return (
		<Box className="nsn-detail-grid">
			<h5>Freight</h5>
			<DataGrid
				autoHeight
				getRowId={() => 10000 * Math.random()}
				density="compact"
				rows={data}
				columns={columns}
				slots={{ columnMenu: NsnDataGridHelpMenu }}
			/>
		</Box>
	);
}
