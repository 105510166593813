// import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
// import {
//   DataGrid as DataGridComponent,
//   Column,
//   Editing,
//   Scrolling,
//   Selection,
//   RequiredRule,
//   MasterDetail,
// } from 'devextreme-react/data-grid';
// import CustomStore from 'devextreme/data/custom_store';
// import {
//   DataType,
//   RowInsertingEvent,
//   RowRemovingEvent,
//   RowUpdatingEvent,
// } from 'devextreme/ui/data_grid_types';
// import { Button } from 'devextreme-react/button';
// import './DataGridSupplierSetup.scss';

// import { Popup } from 'devextreme-react/popup';
// import TextBox from 'devextreme-react/text-box';
// import { LoadIndicator } from 'devextreme-react';

// export interface IDataGridColumn {
//   field: string;
//   caption?: string;
//   dataType: DataType;
//   required: boolean;
//   showLink?: boolean;
//   dataField?: string;
//   cellTemplate?: (container: HTMLElement, options: any) => void;
// }

// interface IPopupFields {
//   key: string;
//   label: string;
// }

// interface IOnSelectionChangedProps {
//   selectedRowKeys: any[];
//   selectedRowsData: any[];
// }

// interface IDataGridProps {
//   ExpandableRowComponent?: React.FC<any>;
//   dataSource: CustomStore | any[];
//   columns: IDataGridColumn[];
//   createData?: (data: any) => void;
//   loadData?: () => void;
//   updateData?: (id: number, data: any, createdSupplierId?: number) => void;
//   deleteData?: (id: number) => void;
//   getLink?: (id: number) => string;
//   linkKeyExpr?: string;
//   popupFields?: IPopupFields[];
//   popupTitle?: string;
//   popupCreateLabel?: string;
//   handleCreate?: (data: any) => void;
//   setSelectedRowsData?: React.Dispatch<React.SetStateAction<any[]>>;
//   customActionButtons?: React.ReactNode;
//   loading: boolean;
// }

// const DataGridSupplierSetup = ({
//   ExpandableRowComponent,
//   dataSource,
//   columns,
//   createData,
//   loadData,
//   updateData,
//   deleteData,
//   getLink,
//   linkKeyExpr,
//   popupFields,
//   popupTitle,
//   popupCreateLabel,
//   handleCreate,
//   setSelectedRowsData,
//   customActionButtons,
//   loading,
// }: IDataGridProps) => {
//   const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
//   const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
//   const [newEntity, setNewEntity] = useState<any>({});
//   const [key, setKey] = useState<number>(0);

//   useEffect(() => {
//     loadData?.();
//   }, []);

//   const renderButton = (cellInfo: any) => {
//     if (linkKeyExpr == null) return;
//     return (
//       cellInfo.data[linkKeyExpr] && (
//         <Button
//           icon="link"
//           onClick={() => window.open(getLink!(cellInfo.data[linkKeyExpr]))}
//         />
//       )
//     );
//   };

//   const openCreateModal = (data: any) => {
//     setNewEntity(data.row.data);
//     setCreateModalVisible(true);
//   };

//   const isEditable = (rowData: any) => {
//     return (
//       updateData !== undefined &&
//       (linkKeyExpr == null || rowData[linkKeyExpr] === null)
//     );
//   };
//   const onSelectionChanged = ({
//     selectedRowKeys: changedRowKeys,
//     selectedRowsData,
//   }: IOnSelectionChangedProps) => {
//     setSelectedRowKeys(changedRowKeys);
//     setSelectedRowsData?.(selectedRowsData);
//   };

//   const handleContentReady = (e: any) => {
//     const noDataElement = e.element.querySelector('.dx-datagrid-nodata');
//     if (loading && noDataElement && !e.component.getDataSource().items().length) {
//       const loadIndicatorContainer = document.createElement('div');
//       loadIndicatorContainer.classList.add('custom-load-indicator');
//       noDataElement.innerHTML = '';
//       noDataElement.appendChild(loadIndicatorContainer);
//       ReactDOM.render(<LoadIndicator visible={true} />, loadIndicatorContainer);
//     }
//   };

//   return (
//     <>
//       <DataGridComponent
//         className="data-grid"
//         key={key}
//         keyExpr={'id'}
//         selectedRowKeys={selectedRowKeys}
//         onSelectionChanged={onSelectionChanged}
//         showBorders={true}
//         dataSource={dataSource}
//         height={400}
//         repaintChangesOnly={false}
//         width="auto"
//         onRowUpdating={async (e: RowUpdatingEvent) => {
//           await updateData!(e.key, { ...e.oldData, ...e.newData });
//           await loadData!();
//         }}
//         onRowInserting={async (e: RowInsertingEvent) => {
//           await createData!(e.data);
//           await loadData!();
//         }}
//         onRowRemoving={async (e: RowRemovingEvent) => {
//           await deleteData!(e.key);
//           await loadData!();
//         }}
//         onContentReady={handleContentReady}
//         noDataText="No data"
//       >
//         <MasterDetail
//           enabled={ExpandableRowComponent !== undefined}
//           autoExpandAll={false}
//           render={ExpandableRowComponent}
//         />

//         <Selection
//           mode={setSelectedRowsData !== undefined ? 'multiple' : 'none'}
//           selectAllMode="page"
//           showCheckBoxesMode="always"
//         />

//         <Editing
//           refreshMode={'full'}
//           mode="cell"
//           allowAdding={createData !== undefined}
//           allowDeleting={deleteData !== undefined}
//           allowUpdating={(e) => isEditable(e.row.data)}
//         />

//         <Scrolling mode="virtual" />

//         {columns?.map((column) =>
//           column.showLink ? (
//             <Column
//               key={column.field}
//               dataField={column.field}
//               caption={column.caption}
//               dataType={column.dataType}
//               type={'buttons'}
//               cellRender={renderButton}
//             />
//           ) : (
//             <Column
//               key={column.field}
//               dataField={column.field}
//               caption={column.caption}
//               dataType={column.dataType}
//             >
//               {column.required && <RequiredRule />}
//             </Column>
//           )
//         )}

//         {getLink !== undefined && (
//           <Column
//             type="buttons"
//             buttons={[
//               {
//                 text: 'Create',
//                 cssClass: 'data-grid-button',
//                 disabled: (cellInfo: any) =>
//                   cellInfo.row.data[linkKeyExpr!] != null,
//                 onClick: openCreateModal,
//               },
//               {
//                 text: 'Delete',
//                 cssClass: 'data-grid-button',
//                 onClick: (e: any) => e.component.deleteRow(e.row.rowIndex),
//               },
//             ]}
//           />
//         )}

//         {getLink === undefined && customActionButtons}
//       </DataGridComponent>

//       <Popup
//         visible={createModalVisible}
//         onHiding={() => setCreateModalVisible(false)}
//         dragEnabled={true}
//         hideOnOutsideClick={true}
//         showTitle={true}
//         title={popupTitle}
//         height="auto"
//         width={500}
//       >
//         {popupFields?.map((field, index) => (
//           <div className={index ? 'padding-top' : ''} key={field.key}>
//             <TextBox
//               label={field.label}
//               value={String(newEntity[field.key])}
//               labelMode="outside"
//               disabled={true}
//             />
//           </div>
//         ))}

//         <div className="popup-footer">
//           <Button
//             className="create-button"
//             text={popupCreateLabel}
//             type="default"
//             stylingMode="contained"
//             onClick={async () => {
//               if (handleCreate === undefined) return;
//               await handleCreate(newEntity);
//               await loadData!();
//               setCreateModalVisible(false);
//               setKey(key + 1);
//             }}
//           />

//           <Button
//             className="cancel-button"
//             text="Cancel"
//             type="normal"
//             stylingMode="contained"
//             onClick={() => setCreateModalVisible(false)}
//           />
//         </div>
//       </Popup>
//     </>
//   );
// };

// export default DataGridSupplierSetup;

import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
	Button,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import "./DataGridSupplierSetup.scss";

export interface IDataGridColumn {
	field: string;
	headerName?: string;
	type?: "number" | "string" | "boolean" | "date";
	required: boolean;
	showLink?: boolean;
	renderCell?: (params: any) => React.ReactNode;
	width?: number;
	minWidth?: number;
}

interface IPopupFields {
	key: string;
	label: string;
}

interface IDataGridProps {
	ExpandableRowComponent?: React.FC<any>;
	dataSource: any[];
	columns: IDataGridColumn[];
	createData?: (data: any) => void;
	loadData?: () => void;
	updateData?: (id: number, data: any) => void;
	deleteData?: (id: number) => void;
	getLink?: (id: number) => string;
	linkKeyExpr?: string;
	popupFields?: IPopupFields[];
	popupTitle?: string;
	popupCreateLabel?: string;
	handleCreate?: (data: any) => void;
	setSelectedRowsData?: React.Dispatch<React.SetStateAction<any[]>>;
	customActionButtons?: React.ReactNode;
	loading: boolean;
}

const DataGridSupplierSetup = ({
	ExpandableRowComponent,
	dataSource,
	columns,
	createData,
	loadData,
	updateData,
	deleteData,
	getLink,
	linkKeyExpr,
	popupFields,
	popupTitle,
	popupCreateLabel,
	handleCreate,
	setSelectedRowsData,
	customActionButtons,
	loading,
}: IDataGridProps) => {
	const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
		[]
	);
	const [createModalVisible, setCreateModalVisible] =
		useState<boolean>(false);
	const [newEntity, setNewEntity] = useState<any>({});
	const [key, setKey] = useState<number>(0);
	const [expandedRow, setExpandedRow] = useState<number | null>(null);

	useEffect(() => {
		loadData?.();
	}, []);

	const handleRowClick = (rowId: number) => {
		setExpandedRow((prev) => (prev === rowId ? null : rowId));
	};

	const renderButton = (params: any) => {
		if (!linkKeyExpr) return null;
		return (
			<IconButton
				onClick={() => window.open(getLink!(params.row[linkKeyExpr]))}
			>
				<LinkIcon />
			</IconButton>
		);
	};

	const openCreateModal = (row: any) => {
		setNewEntity(row);
		setCreateModalVisible(true);
	};

	const isEditable = (row: any) => {
		return updateData !== undefined && (!linkKeyExpr || !row[linkKeyExpr]);
	};

	const columnDefs: GridColDef[] = [
		// Check if an "expand" column is already present in the supplied columns
		columns.find((col) => col.field === "expand") || {
		  field: "expand",
		  headerName: "",
		  renderCell: (params) => (
			<IconButton onClick={() => handleRowClick(params.row.id)}>
			  {expandedRow === params.row.id ? (
				<ExpandMoreIcon />
			  ) : (
				<ChevronRightIcon />
			  )}
			</IconButton>
		  ),
		  minWidth: 40,
		  maxWidth: 40,
		  flex: 0.1,
		},
		// Spread the rest of the columns
		...columns
		  .filter((column) => column.field !== "expand") // Exclude "expand" if already included
		  .map((column) => ({
			field: column.field,
			headerName: column.headerName,
			type: column.type,
			renderCell: column.renderCell,
			editable: false,
			flex: 1,
			headerAlign: "center" as "center" | "left" | "right",
			align: "center" as "center" | "left" | "right",
		  })),
	  ];

	const onSelectionChanged = (newSelectionModel: GridRowSelectionModel) => {
		const selectedRows = dataSource.filter((row: any) =>
			newSelectionModel.includes(row.id)
		);
		setSelectionModel(newSelectionModel);

		if (setSelectedRowsData) {
			setSelectedRowsData(selectedRows);
		}
	};

	const processRowUpdate = async (newRow: any) => {
		const updatedRow = { ...newRow, isNew: false };

		if (updateData) {
			await updateData(updatedRow.id, updatedRow);
			await loadData?.();
		}

		return updatedRow;
	};

	return (
		<>
			<div className="data-grid-container no-pagination">
				<DataGrid
					rows={dataSource}
					columns={columnDefs}
					checkboxSelection={!!setSelectedRowsData}
					onRowSelectionModelChange={onSelectionChanged}
					disableRowSelectionOnClick
					autoHeight
					sx={{
						"& .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
							backgroundColor: "transparent !important",
						},
						"& .MuiDataGrid-cell": {
							padding: "0 !important",
						},
						"& .MuiDataGrid-cell.Mui-selected": {
							backgroundColor: "transparent !important",
						},
						"& .MuiButtonBase-root:focus": {
							outline: "none !important",
						},
						"& .MuiButtonBase-root": {
							fontSize: "0.8rem",
						},
						"& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
							{
								outline: "none",
								boxShadow: "none",
							},
						"& .MuiDataGrid-columnHeader": {
							fontSize: "0.9rem",
						},
						"& .MuiDataGrid-columnHeaderTitle": {
							textOverflow: "clip",
						},
					}}
				/>
				{expandedRow !== null && ExpandableRowComponent && (
					<div
						style={{
							paddingLeft: "10px",
							marginTop: "-40px",
							paddingTop: "0",
						}}
					>
						<ExpandableRowComponent
							data={dataSource.find(
								(row) => row.id === expandedRow
							)}
						/>
					</div>
				)}
			</div>
			<Dialog
				open={createModalVisible}
				onClose={() => setCreateModalVisible(false)}
			>
				<DialogTitle>{popupTitle}</DialogTitle>
				<DialogContent>
					{popupFields?.map((field, index) => (
						<TextField
							key={field.key}
							label={field.label}
							value={String(newEntity[field.key])}
							disabled
							fullWidth
							margin="normal"
						/>
					))}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={async () => {
							if (handleCreate) {
								await handleCreate(newEntity);
								await loadData!();
								setCreateModalVisible(false);
								setKey(key + 1);
							}
						}}
						variant="contained"
					>
						{popupCreateLabel}
					</Button>
					<Button
						onClick={() => setCreateModalVisible(false)}
						variant="outlined"
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>

			{customActionButtons}
		</>
	);
};

export default DataGridSupplierSetup;
