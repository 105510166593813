interface DataItem {
  status?: string;
  [key: string]: any;
}

const compareStatusDates = (statusArray: string[]): DataItem[] => {
  const currentDate = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

  return statusArray.map((statusStr) => {
    const [statusDateStr] = statusStr.split(':').map((part) => part.trim());
    const statusDate = new Date(statusDateStr);

    const isMoreThanOneYear = statusDate < oneYearAgo;

    return {
      isMoreThanOneYear,
    };
  });
};

export default compareStatusDates;
