

import React, { useEffect, useState } from 'react';
import './RiskDiscoveryHits.scss';
import Card from '../../card/Card';
import { useDispatch, useSelector } from 'react-redux';
import DataGridSupplierSetup, {
  IDataGridColumn,
} from '../../../components/data-grid/DataGridSupplierSetup';
import { createColumn, extractIds } from '../../../utils/data-grid';
import RiskDiscoverySelectBox from '../risk-discovery-select-box/RiskDiscoverySelectBox';
import ArticlesAPI from '../../../api/articles/articlesAPI';
import RiskDiscoveryApi from '../../../api/riskDiscovery/riskDiscoverylAPI';
import ActionId from '../consts/actions';
import PopupArticle from '../risk-discovery-popup/PopupArticle';
import { useParams, useLocation } from 'react-router';
import { setKeywordModel, setRiskCase, setSnackbar } from '../../../slices/discoverySlice';
import {
  addArticleToCollection,
  articleRiskCase,
  createCollection,
  removeArticleFromCurrent,
  setCurrent,
  setCurrentLoading,
} from '../../../slices/hitsSlice';
import PopupRiskCases from '../popup-risk-cases/PopupRiskCases';
import RiskCaseApi from '../../../api/riskCase/riskCaseApi';
import { addRiskCases } from '../../../slices/riskCasesSlice';
import { IconButton, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArchiveIcon from '@mui/icons-material/Archive';
import PermissionsApi from '../../../api/permissions/permissionsAPI';
import { addOwners } from '../../../slices/permissionsSlice';
import DiscoveryType from '../consts/discoveryType';

interface SupplierArticle {
  id: string;
  name: string;
}

interface ArticleKeyword {
  id: string;
  name: string;
}

interface ArticleRiskCase {
  id: number;
  riskCaseId: number;
  articleId: number;
}

interface KeywordModel {
  id: number;
  modelName: string;
}

interface Supplier {
  id: number;
  name: string;
}

interface ArchivedKeywordModel {
  id: number;
  modelName: string;
}

interface ArchivedSupplierModel {
  id: number;
  name: string;
}

interface Article {
  id: number;
  title: string;
  pubDate?: string;
  sourceDomain: string;
  url: string;
  sentimentMetrics: string;
  semanticSummary: string;
  content: string;
  imageUrl: string;
  suppliers: string;
  positiveSentiment: number;
  negativeSentiment: number;
  supplierArticles?: SupplierArticle[];
  articleKeywords?: ArticleKeyword[];
  articleRiskCases?: ArticleRiskCase[];
}
interface RiskCases {
  id: number;
  riskCaseName: string;
  description: string;
  ownerId: number;
  subscribers: any;
  keywords: any;
  createdById: number;
  dateAdded: string;
  dateUpdated: string;
  dateDeleted: string;
  isActive: boolean;
  isDeleted: boolean;
  measures: any[];
  suppliers: any[];
  logEntries: any[];
}


interface IRiskCase {
  id: number;
  name: string;
  description: string;
  ownerId: number;
  subscribers: [];
  suppliers: [];
  keywords: [];
  measures: any[];
  logEntries: any[];
  dateAdded?: string;
  dateUpdated?: string;
  dateDeleted?: string;
  isActive?: boolean;
  isDeleted?: boolean;
}

interface IRiskDiscoveryHitsProps {
  keyValue: number;
  setKey: React.Dispatch<React.SetStateAction<number>>;
}

export const createColumns = (
  handleRiskCaseClick: (id: number) => void,
  handleViewClick: (id: number) => void,
  getArticles: () => void,
  handleExpandClick?: (rowData: any) => void
): IDataGridColumn[] => [
  {
    field: 'expand',
    headerName: '',
    type: 'string',
    width: 1,
    minWidth: 30,
    renderCell: (params) => (
      <span
        onClick={() => handleExpandClick && handleExpandClick(params.row)}
        style={{ cursor: 'pointer', width: '1' }}
      >
        {params.row.articles && params.row.articles.length > 0 ? '▶' : ''}
      </span>
    ),
    required: false,
  },
  createColumn('title', 'Title'),
  createColumn('sourceDomain', 'Source Domain'),
  createColumn('score', 'Relevance'),
  createColumn('positiveSentiment', '+ Sentiment'),
  createColumn('negativeSentiment', '- Sentiment'),
  createColumn('suppliers', 'Suppliers'),
  createColumn('description', 'Summary Excerpt'),
  {
    field: 'riskCases',
    headerName: 'Risk Cases',
    type: 'string',
    renderCell: (params) => {
      if (
        params.row.articleRiskCases &&
        params.row.articleRiskCases.length > 0
      ) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => handleRiskCaseClick(params.row.id)}
              title="View Risk Cases"
            >
              <VisibilityIcon />
            </IconButton>
          </div>
        );
      }
      return null;
    },
    required: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    type: 'string',
    renderCell: (params) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => handleViewClick(params.row.id)}
        >
          View
        </Button>
        <IconButton
          onClick={async (e) => {
            await RiskDiscoveryApi.archiveArticles([params.row.id]);
            getArticles();
          }}
          title="Archive"
        >
          <ArchiveIcon />
        </IconButton>
      </div>
    ),
    required: false,
  },
];

const RiskDiscoveryHits = ({ keyValue, setKey }: IRiskDiscoveryHitsProps) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [articles, setArticles] = useState<Article[]>([]);

  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const isKwmRoute = location.pathname.includes('/kwm/');
  const isSupplierRoute = location.pathname.includes('/supplier/');
  const isArchivedKeywordRoute =
    location.pathname.includes('/archived-keyword/');
  const isArchivedSupplierRoute = location.pathname.includes(
    '/archived-supplier/'
  );



  const dispatch = useDispatch();
  const keywordModel = useSelector(
    (state: any) => state.discovery.keywordModel
  );
  const currentHits = useSelector((state: any) => state.hits.current);
  const loading = useSelector((state: any) => state.hits.current.loading);

  const processedArticles = currentHits.data.map((article: Article) => ({
    ...article,
    hasRiskCasesIcon:
      article.articleRiskCases && article.articleRiskCases.length > 0,
  }));

  const keywordModelEntry = useSelector((state: any) => {
    if (isKwmRoute) {
      return (
        Object.entries(state.discovery.keywordModel.byKeywordModel) as [
          string,
          KeywordModel
        ][]
      ).find(([key, model]) => model.id === Number(id));
    }
    return undefined;
  });

  const supplierEntry = useSelector((state: any) => {
    if (isSupplierRoute) {
      return (
        Object.entries(state.discovery.keywordModel.bySupplier) as [
          string,
          Supplier
        ][]
      ).find(([key, supplier]) => supplier.id === Number(id));
    }
    return undefined;
  });


  const archivedKeywordModelEntry = useSelector((state: any) => {
    if (isArchivedKeywordRoute) {
      return (
        Object.entries(
          state.discovery.keywordModel.archivedHits.byKeywordModel
        ) as [string, ArchivedKeywordModel][]
      ).find(([key, archivedModel]) => archivedModel.id === Number(id));
    }
    return undefined;
  });

  const archivedSupplierEntry = useSelector((state: any) => {
    if (isArchivedSupplierRoute) {
      return (
        Object.entries(
          state.discovery.keywordModel.archivedHits.bySupplier
        ) as [string, ArchivedSupplierModel][]
      ).find(([key, archivedModel]) => archivedModel.id === Number(id));
    }
    return undefined;
  });

  const getArticles = async () => {
    dispatch(setCurrentLoading(true));
    let response;
    try {
      if (isKwmRoute) {
        response = await ArticlesAPI.getArticlesByKeywordModel(
          Number(id),
          false
        );
      } else if (isSupplierRoute) {
        response = await ArticlesAPI.getArticlesBysupplier(Number(id), false);
      } else if (isArchivedKeywordRoute) {
        response = await ArticlesAPI.getArticlesByKeywordModel(
          Number(id),
          true
        );
      } else if (isArchivedSupplierRoute) {
        response = await ArticlesAPI.getArticlesBysupplier(Number(id), true);
      }
      if (response) {
        dispatch(setCurrent(response));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setCurrentLoading(false));
    }
  };

  const fetchDataRiskCases = async () => {
    try {
      const data = await RiskCaseApi.getAllRiskCase();
      dispatch(addRiskCases(data));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataOwners = async () => {
    try {
      const data = await PermissionsApi.getAllOwners();
      dispatch(addOwners(data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!keywordModel || Object.keys(keywordModel).length === 0) {
      const fetchData = async () => {
        try {
          const data = await RiskDiscoveryApi.getRiskDiscoveryMenu();
          dispatch(setKeywordModel(data));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [keywordModel, dispatch]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        dispatch(setCurrentLoading(true));
        await getArticles();
      } catch (error) {
        console.error(error);
      }
    };
    fetchArticles();
    fetchDataRiskCases();
    fetchDataOwners();
  }, [id, dispatch]);

  const openPopup = (riskCase: IRiskCase, ids: any) => {
    const completeRiskCase: RiskCases = {
      id: riskCase.id,
      riskCaseName: riskCase.name,
      description: riskCase.description,
      ownerId: riskCase.ownerId || 0,
      subscribers: riskCase.subscribers || [],
      keywords: riskCase.keywords || [],
      createdById: 0,
      measures: riskCase.measures || [],
      suppliers: riskCase.suppliers || [],
      logEntries: riskCase.logEntries || [],
      dateAdded: riskCase.dateAdded || '',
      dateUpdated: riskCase.dateUpdated || '',
      dateDeleted: riskCase.dateDeleted || '',
      isActive: riskCase.isActive || true,
      isDeleted: riskCase.isDeleted || false,
    };
    dispatch(
      setRiskCase({ data: riskCase, isPopupVisible: true, articleIds: ids }), 
    );
  };

  const handleAction = async (
    selectedAction: { actionId?: ActionId },
    selectedRelatedAction: any
  ) => {
    const ids = extractIds(selectedRows);

    let riskCaseArticle: any[] = [];   

    if (selectedAction.actionId === ActionId.CreateRiskCase || selectedAction.actionId === ActionId.AddToRiskCase) {
      riskCaseArticle = currentHits.data.filter((article: any) =>
        ids.includes(article.id)
      );
      
    };

    try {
      switch (selectedAction.actionId) {
        case ActionId.AddToHitCollection:
          for (const id of ids) {
            const article = currentHits.data.find(
              (article: Article) => article.id === id
            );
            if (article) {
              dispatch(
                addArticleToCollection({
                  collectionId: selectedRelatedAction.id,
                  article,
                })
              );
              dispatch(removeArticleFromCurrent(article.id));
            }
          }
          await RiskDiscoveryApi.addArticlesToCollection(
            selectedRelatedAction.id,
            ids
          );
          setKey(keyValue + 1);
          break;
        case ActionId.CreateHitCollection:
          const newCollection = {
            collectionName: selectedRelatedAction.collectionName,
            id: Date.now(),
            articleCount: 0,
            avgSent: 0,
            articles: [],
            dateAdded: new Date().toISOString(),
            dateDeleted: null,
            dateUpdated: null,
            isActive: true,
            isDeleted: false,
            kwmOrSupplierId: idForSelectBox,
            discoveryTypeName: DiscoveryType[discoveryType],
          };
          const articles = currentHits.data.filter((article: Article) =>
            ids.includes(article.id)
          );
          dispatch(createCollection({ collection: newCollection, articles }));
          for (const id of ids) {
            dispatch(removeArticleFromCurrent(id));
          }
          await RiskDiscoveryApi.createCollection(
            selectedRelatedAction,
            idForSelectBox,
            ids,
            DiscoveryType[discoveryType]
          );
          setKey(keyValue + 1);
          break;
        case ActionId.AddToRiskCase:
          await RiskDiscoveryApi.addArticlesToRiskCase(
            selectedRelatedAction.id,
            ids
          );
          dispatch(setSnackbar({
            open: true,
            severity: "success",
            message: `Article${ids.length > 1 ? 's' : ''} successfully added to the risk case.`,
          }));
          break;
        case ActionId.CreateRiskCase:
          const kwmId = keywordModelEntry == null || keywordModelEntry.length < 1 
            ? null 
            : keywordModelEntry![1].id

            const response = await RiskDiscoveryApi.createArticleRiskCase(
            selectedRelatedAction,
            ids,
            kwmId,
          );
          dispatch(
            articleRiskCase({
              articles: riskCaseArticle,
            }),

            
          );
          openPopup(response, ids);
          break;
        case ActionId.Archive:
          await RiskDiscoveryApi.archiveArticles(ids);
          break;
      }
    } catch (error) {
      console.error('Error handling action:', error);
    }

    getArticles();
  };

  const handleViewClick = (articleId: number) => {
    let foundIndex = -1;
    let allArticles: Article[] = [];
    let isArticleCollection =
      currentHits.data.articles !== null &&
      currentHits.data.articles !== undefined;
    if (isArticleCollection) {
      currentHits.data.forEach((collection: any) => {
        if (collection.articles) {
          allArticles = [...allArticles, ...collection.articles];
        }
      });
    } else {
      allArticles = currentHits.data;
    }

    foundIndex = allArticles.findIndex(
      (article: Article) => article.id === articleId
    );

    setArticles(allArticles);
    setCurrentIndex(foundIndex);
    setPopupVisible(true);
  };

  const handleArchiveClick = async (articleId: number) => {
    try {
      await RiskDiscoveryApi.archiveArticles([articleId]);
      await getArticles();
    } catch (error) {
      console.error('Error archiving article:', error);
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
  };

  const handleRiskCaseClick = (articleId: number) => {
    let foundIndex = -1;
    let allArticles: Article[] = [];
    let isArticleCollection =
      currentHits.data.articles !== null &&
      currentHits.data.articles !== undefined;
    if (isArticleCollection) {
      currentHits.data.forEach((collection: any) => {
        if (collection.articles) {
          allArticles = [...allArticles, ...collection.articles];
        }
      });
    } else {
      allArticles = currentHits.data;
    }

    foundIndex = allArticles.findIndex(
      (article: Article) => article.id === articleId
    );

    setArticles(allArticles);
    setCurrentIndex(foundIndex);
    setPopupRiskCasesVisible(true);
  };

  const handleRiskCasesClose = () => {
    setPopupRiskCasesVisible(false);
  };

  const columns = createColumns(
    handleRiskCaseClick,
    handleViewClick,
    getArticles
  );

  const modelName = isKwmRoute
    ? keywordModelEntry?.[1]?.modelName || keywordModelEntry?.[0]
    : isSupplierRoute
    ? supplierEntry?.[1]?.name || supplierEntry?.[0]
    : isArchivedKeywordRoute
    ? archivedKeywordModelEntry?.[1]?.modelName ||
      archivedKeywordModelEntry?.[0]
    : archivedSupplierEntry?.[1]?.name || archivedSupplierEntry?.[0];

  const idForSelectBox = isKwmRoute
    ? keywordModelEntry?.[1]?.id
    : isSupplierRoute
    ? supplierEntry?.[1]?.id
    : isArchivedKeywordRoute
    ? archivedKeywordModelEntry?.[1]?.id
    : archivedSupplierEntry?.[1]?.id || 0;

  const discoveryType = isKwmRoute
    ? DiscoveryType.ByKWM
    : DiscoveryType.BySupplier;

  return (
    <Card>
      <h4>Current Hits - {modelName}</h4>

      <RiskDiscoverySelectBox
        disabled={selectedRows.length === 0}
        mode="hits"
        kwmOrSupplierId={idForSelectBox}
        key={selectedRows.length}
        handleAction={handleAction}
        discoveryType={discoveryType}
      />

      <DataGridSupplierSetup
        dataSource={processedArticles}
        columns={columns}
        setSelectedRowsData={setSelectedRows}
        loadData={getArticles}
        loading={loading}
        customActionButtons={null}
      />

      {popupVisible && articles[currentIndex] && (
        <PopupArticle
          articles={articles}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          onClose={handlePopupClose}
        />
      )}

      <PopupRiskCases
        popupRiskCasesVisible={popupRiskCasesVisible}
        handleRiskCasesClose={handleRiskCasesClose}
        articles={articles}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </Card>
  );
};

export default RiskDiscoveryHits;
