export const formatDate = (dateStr) => {
  // If it's null there's no need to format it
  if(dateStr === null) return null;

  const date = new Date(dateStr);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export const formatCurrency = (number) => {
  // If it's null there's no need to format it
  if(number === null) return null;

  // Check if the number is an integer
  if (Number.isInteger(number)) {
    // If it's an integer, convert it to a string and format it as currency without decimals
    return `$${number.toLocaleString()}`;
  } else {
    // If it's not an integer, use formatting for decimals
    const parts = number.toFixed(2).split('.');
    const formattedThousands = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return `$${formattedThousands}.${parts[1]}`;
  }
};
