import React from 'react';
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Sorting,
} from 'devextreme-react/data-grid';
import { formatDate } from '../../../../../utils/format-helpers';
import './index.scss';

const DetailTemplate = ({ data }) => (
  <div>
    <p className="filing-datagrid-secondary-title">AGENCY COMMENTS</p>
    <div className="agency-comments-box">
      {data.data.agencyComments}
    </div>
  </div>
);

const ExclusionsComponent = ({ exclusionsData }) => {
  const activeExclusions = exclusionsData?.filter(exclusion => exclusion.isActiveExclusion);
  const inactiveExclusions = exclusionsData?.filter(exclusion => !exclusion.isActiveExclusion);

  return (
    <>
      <p className="filing-datagrid-primary-title">Exclusions</p>
      <p className="filing-datagrid-primary-subtitle">Active Exclusions</p>
      <DataGrid
        dataSource={activeExclusions}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnReordering={true}
      >
        <Paging enabled={false} />
        <Sorting mode="multiple" />
        <Column
          dataField="effectiveDate"
          caption="Eff. Date"
          cellRender={(cellData) => formatDate(cellData.data.effectiveDate)}
        />
        <Column
          dataField="expirationDate"
          caption="Exp. Date"
          cellRender={(cellData) => formatDate(cellData.data.expirationDate)}
        />
        <Column dataField="agency" caption="Agency" />
        <Column dataField="agencyComments" caption="Agency Comments" cellRender={(cellData) => (
          <div className="agency-comments-cell">
            {cellData.data.agencyComments}
          </div>
        )} />
        <MasterDetail enabled={true} component={DetailTemplate} />
      </DataGrid>
      <p className="filing-datagrid-primary-subtitle">Inactive Exclusions</p>
      <DataGrid
        dataSource={inactiveExclusions}
        showBorders={true}
        columnAutoWidth={true}
        allowColumnReordering={true}
      >
        <Paging enabled={false} />
        <Sorting mode="multiple" />
        <Column
          dataField="effectiveDate"
          caption="Eff. Date"
          cellRender={(cellData) => formatDate(cellData.data.effectiveDate)}
        />
        <Column
          dataField="expirationDate"
          caption="Exp. Date"
          cellRender={(cellData) => formatDate(cellData.data.expirationDate)}
        />
        <Column dataField="agency" caption="Agency" />
        <Column dataField="agencyComments" caption="Agency Comments" cellRender={(cellData) => (
          <div className="agency-comments-cell">
            {cellData.data.agencyComments}
          </div>
        )} />
        <MasterDetail enabled={true} component={DetailTemplate} />
      </DataGrid>
    </>
  )
}

export default ExclusionsComponent;