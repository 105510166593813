import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createColumn } from "../../../utils/data-grid";
import { NsnDataGridHelpMenu } from "./NsnDataGridHelpMenu";
import "./NsnDetails.scss";

export default function NsnDetailSolicitationsDataGrid({ data }) {
	// Create column definitions	// Create column definitions
	const columns = [
		{
			...createColumn("SOLICITATION", "SOLICITATION"),
			minWidth: 100,
			flex: 1,
			renderCell: (props) => {
				console.log(props.value);
				return (
					<a
						className="grid-link"
						href={
							"https://www.dibbs.bsm.dla.mil/rfq/rfqrec.aspx?sn=" +
							props.value
						}
						target="_blank"
						rel="noreferrer"
					>
						{props.value}
					</a>
				);
			},
		},
		{ ...createColumn("FSC", "FSC"), width: 80 },
		{ ...createColumn("NIIN", "NIIN"), width: 120 },
		{ ...createColumn("POSTDATE", "POST DATE"), width: 120 },
		{ ...createColumn("RTNBYDATE", "RTN BY DATE"), width: 120 },
		{ ...createColumn("QTY", "QTY", "number"), width: 80 },
		{
			...createColumn("UNIT_PR", "UNIT PR", "number"),
			width: 80,
			valueFormatter: (value) => {
				if (value == null) return "";

				return new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
					minimumFractionDigits: 2,
				}).format(value);
			},
		},
		{
			...createColumn("ESTVALUE", "EST VALUE", "number"),
			width: 120,
			valueFormatter: (value) => {
				if (value == null) return "";

				return new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
					minimumFractionDigits: 2,
				}).format(value);
			},
		},
		{ ...createColumn("UI", "UI"), width: 80 },
		{ ...createColumn("AMSC", "AMSC"), width: 80 },
		{ ...createColumn("SBSETASIDEIND", "SBS ETA SIDE IND"), width: 150 },
		{ ...createColumn("SBSETASIDEPCT", "SBS ETA SIDE PCT"), width: 150 },
		{ ...createColumn("SOLTYPEIND", "SOL TYPE IND"), width: 150 },
	];

	return (
		<Box className="nsn-detail-grid">
			<h5>Solicitations</h5>
			<DataGrid
				autoHeight
				getRowId={() => 10000 * Math.random()}
				density="compact"
				rows={data}
				columns={columns}
				slots={{ columnMenu: NsnDataGridHelpMenu }}
			/>
		</Box>
	);
}
